import Aboutus from "./Aboutus";
import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import img1 from "../images/11.png";
import img2 from "../images/22.png";
import img3 from "../images/33.png";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import sl1 from "../images/xpllora2.png";
import sl2 from "../images/Frame384.png";
import sl3 from "../images/xpllora5.png";
import sl4 from "../images/xpllora3.png";
const Home = () => {
  // const sliderimg=[
  //      {id:1,img:'https://images.pexels.com/photos/2781814/pexels-photo-2781814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'

  //      },
  //      {id:2,img:'https://static.vecteezy.com/system/resources/thumbnails/010/514/729/small_2x/back-to-school-background-free-vector.jpg'

  //      },
  //      {id:3,img:'https://www.usnews.com/object/image/0000017c-5b23-d288-abfd-7f2f8fd60000/211007-artclass-stock.jpg?update-time=1633616679533&size=responsive640'

  //      },
  //      {id:4,img:'https://enrollbasis.com/wp-content/uploads/2023/01/Benefits-of-Extra-Curricular-Activities-990x500-1-990x500.jpg'

  //      },

  // ]
  const sliderimg = [
    { id: 1, img: sl1 },
    { id: 2, img: sl2 },
    { id: 3, img: sl3 },
    {
      id: 4,
      img: sl4,
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === sliderimg.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? sliderimg.length - 1 : prevSlide - 1
    );
  };
  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(slideInterval);
  }, []);
  return (
    <section id="home" className="h-auto md:h-screen md:mt-40 w-full mt-20">
      <div className="md:w-[100%] w-screen  h-full md:mt-28 mt-16 relative  overflow-y-hidden overflow-hidden ">
        <div
          className="transition ease-out duration-40 "
          //  style={{
          //     transform: `translateX(-${currentSlide *100 }%)`
          // }}
        >
          <img
            src={sliderimg[currentSlide].img}
            alt=""
            className="w-full  h-full object-contain"
          />
        </div>
        <div className="top-0 absolute hidden md:flex items-center justify-between h-full w-full text-black px-3 md:px-5 text-2xl">
          <button onClick={prevSlide} className="bg-secondary p-2 rounded-full">
            <IoIosArrowBack className="text-primary" />
          </button>
          <button onClick={prevSlide} className="bg-secondary p-2 rounded-full">
            <IoIosArrowForward className="text-primary" />
          </button>
        </div>

        <div className="bottom-0 absolute py-4 hidden md:flex justify-center left-[50%]  gap-3">
          {sliderimg.map((s, i) => (
            <div
              key={i}
              className={`rounded-full h-2 w-2  ${
                i == currentSlide ? "bg-primary" : "bg-secondary"
              }`}
            ></div>
          ))}
        </div>

        <div className="md:absolute md:top-[25rem] md:left-[8rem]  grid grid-cols-1 md:grid-cols-3 md:gap-10 mt-5 md:mt-10  px-3 ">
          <div className="bg-primary  hover:bg-secondary  xs:h-64 h-60 md:w-96 w-auto p-3 flex  space-x-8  py-8">
            <div className="w-340">
              <img
                src={img1}
                width={40}
                alt=""
                className="h-full w-full object-contain object-top"
              />
            </div>
            <div className="flex flex-col space-y-3 text-white">
              <p className="text-white font-bold text-xl md:text-2xl uppercase">
                Before Preschool
              </p>
              <p>
                Implementing a Comprehensive and Engaging Preschool Education
                Program based on Excel-Based Learning Syllabus
              </p>
              <div className="flex items-center space-x-2">
                <Link className="text-lg">Learn More</Link>
                <FaLongArrowAltRight className="text-lg mt-1" />
              </div>
            </div>
          </div>
          <div className="bg-secondary xs:h-64 h-60 md:w-96 w-auto flex p-3 space-x-8 py-8">
            <div>
              <img
                src={img2}
                width={40}
                alt=""
                className="h-full w-full object-contain object-top"
              />
            </div>
            <div className="flex flex-col space-y-3 text-white">
              <p className="text-white font-bold text-xl md:text-2xl uppercase">
                Skills Development
              </p>
              <p>
                Implementing programs for kids to develop their personality
                development and to solve problems with understanding.
              </p>
              <div className="flex items-center space-x-2">
                <Link className="text-lg">Learn More</Link>
                <FaLongArrowAltRight className="text-lg mt-1" />
              </div>
            </div>
          </div>
          <div className="bg-primary hover:bg-secondary xs:h-64 h-60 md:w-96  w-auto flex p-3 space-x-8 py-8">
            <div className="w-40">
              <img
                src={img3}
                width={40}
                alt=""
                className="h-full w-full object-contain object-top"
              />
            </div>
            <div className="flex flex-col space-y-3 text-white">
              <p className="text-white font-bold text-xl md:text-2xl uppercase ">
                English & ICT Courses
              </p>
              <p>
                Specially designed for kids students after completing GCE O/L
                and A/L and for those seeking employment{" "}
              </p>
              <div className="flex items-center space-x-2">
                <Link className="text-lg">Learn More</Link>
                <FaLongArrowAltRight className="text-lg mt-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
