import React from "react";
import { PiStudentFill } from "react-icons/pi";
import img1 from "../images/gallery/abimg1.webp";
import img2 from "../images/gallery/abimg2.webp";

const Aboutus = () => {
  return (
    <section id="aboutus" className="bg-blue-100 h-auto py-24 ">
      <div className="md:container md:mx-auto flex w-full md:flex-row flex-col px-4">
        <div className="md:w-1/2  w-full flex md:space-x-6 space-x-7">
          <div className="bg-slate-300 w-2/5 xs:w-full h-96 border-4 rounded-xl border-primary xs:border-0">
            <img
              src={img1}
              alt=""
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
          <div className="flex items-end  w-2/5 xs:hidden">
            <div className="bg-slate-300 h-80 rounded-xl border-4 border-secondary w-full  ">
              <img
                src={img2}
                alt=""
                className="h-full w-full rounded-xl object-cover"
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full mt-3 md:mt-0  px-5 flex flex-col space-y-4">
          <div className="flex space-x-3">
            <PiStudentFill className="text-secondary text-3xl" />
            <p className="text-secondary font-bold text-xl">About Our School</p>
          </div>
          <div>
            <p className="md:text-5xl font-bold text-3xl">
              A Few Words About the School
            </p>
          </div>
          <p className="text-gray-800 text-xl">
            Our institution was opened in 2013 in Manipay. We have employed
            qualified teachers to provide quality and current education for
            students in our area. This institution has accelerated special
            programs for our target group in personality development courses to
            suit our society . We have located our institution in a safety place
            to launch educational programs in a friendly healthy manner.
          </p>

          <p className="text-gray-600 ">
            At present our head office is functioning in Manipay and we have an
            additional branch in Mallakam.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
