import {configureStore} from '@reduxjs/toolkit';

import staffreducer from './slice/staffSlice';

import userReducer from './slice/userSlice';
import studentSlice from './slice/student'
import xstudentSlice from './slice/xpllorasthudent'
import courseSlice from './slice/course'
import Staff from './slice/staff';
import paymentSLice from './slice/paymentSlice'
import preschool_courseSlice from './slice/preschool_course';
import prescl_payment from './slice/prescl_payment'
import academicstaffSlic from './slice/academicstaff'
import extraactivitySlice from './slice/extraActivitySlice'
import examSlice from './slice/examSlice'
import examPaymentSlice from './slice/examPaymentSlice'
import extraActivitySlice from './slice/extraactivityPayment'
import userSlice2 from './slice/UserSlice2'
import xpllorapytmreport from './slice/xplorapytreportSlice'
import xplorapytreportSlice from './slice/xplorapytreportSlice';
import presclpytreportslice from './slice/PresclpytreportSlice'
// import prestu from './reducer'
// import { createStore } from "redux"

const  store = configureStore({
reducer:{
    
    staffState : staffreducer,
    userState2:userSlice2,
    userState:userReducer,
    studentState:studentSlice,
    xstudentState:xstudentSlice,
    courseState:courseSlice,
    Employee : Staff,
    paymentState:paymentSLice,
    presclgradeState:preschool_courseSlice,
    presclPaymentState:prescl_payment,
    academicstaffState:academicstaffSlic,
    extraActivityState: extraactivitySlice,
    examState:examSlice,
    examPayment:examPaymentSlice,
    extraActivityPayment: extraActivitySlice,
    xpllorpytmreport:xplorapytreportSlice,
    presclpytreport:presclpytreportslice
}
})

// export default store


export default store