import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
const Footer = () => {
  return (
    <div>
      <div className="bg-primary md:h-96 h-auto md:px-20 px-10">
        <div className="container  grid grid-cols-1 md:grid-cols-4 gap-10 md:justify-items-center py-10 ">
          <div className="w-full">
            <div className="w-1/2">
              <h1 className="text-2xl font-bold text-white border-b-2 w-[108px] border-b-secondary py-1">
                About Us
              </h1>
            </div>
            <p className="mt-5 text-gray-300 text-lg">
              Suspendisse non sem ante. Cras pretium gravida leo a convallis.
              Nam malesuada interdum metus, sit amet dictum ante congue eu.
              Maecenas ut maximus enim.
            </p>
            <div className="mt-5 flex space-x-5">
              <button className="bg-gray-600 rounded-full p-2 text-white text-xl">
                <FaFacebookF />
              </button>
              <button className="bg-gray-600 rounded-full p-2 text-white text-xl">
                <FaInstagram />
              </button>
              <button className="bg-gray-600 rounded-full p-2 text-white text-xl">
                <FaTwitter />
              </button>
            </div>
          </div>
          <div className="w-full">
            <div className="w-1/2">
              <h1 className="text-2xl font-bold text-white border-b-2 w-[107px] border-b-secondary py-1">
                Our Links
              </h1>
            </div>

            <ul className="mt-5 text-gray-300 text-lg  flex flex-col space-y-3">
              <li>Home</li>
              <li>About Us</li>
              <li>Our Courses</li>
              <li>Our Best Feature</li>
              <li>FAQ</li>
            </ul>
          </div>
          <div className="w-full">
            <div className="w-1/2">
              <h1 className="text-2xl font-bold text-white border-b-2 w-[124px] border-b-secondary py-1">
                Latest Post
              </h1>
            </div>
            <div className="flex h-24 mt-5 space-x-3 ">
              <div className="w-36 h-full">
                <img
                  src="https://gisgondal.com/wp-content/uploads/2020/12/gis_pre.jpg"
                  className="rounded-md w-full h-full object-cover"
                  alt=""
                />
              </div>
              <p className="text-gray-300  mt-3">
                If you put in the effort, nothing is impossible.
              </p>
            </div>
            <div className="flex h-24 mt-5 space-x-3 ">
              <div className="w-36 h-full">
                <img
                  src="https://www.21kschool.com/lk/wp-content/uploads/sites/24/2022/09/Top-5-Benefits-of-Co-Curricular-Activities-for-Students.png"
                  className="rounded-md w-full h-full object-cover"
                  alt=""
                />
              </div>
              <p className="text-gray-300 mt-3">
                If you put in the effort, nothing is impossible.
              </p>
            </div>
          </div>
          <div className="w-full">
            <div className="w-1/2">
              <h1 className="text-2xl font-bold text-white  py-1 border-b-2 w-[124px] border-b-secondary">
                Contact Us
              </h1>
            </div>
            <div className="mt-5 flex flex-col space-y-5">
              <div className="flex space-x-4 items-center">
                <button className="bg-secondary p-3  rounded-full text-white text-2xl">
                  <IoCall />
                </button>
                <div className="text-gray-300 ">
                  <p>021-2256200</p>
                  <p>077 6168584</p>
                </div>
              </div>
              <div className="flex space-x-4 items-center">
                <button className="bg-secondary p-3  rounded-full text-white text-2xl">
                  <MdOutlineMailOutline />
                </button>
                <div className="text-gray-300">
                  <p>info@yourdomain.com</p>
                  <p>support@yourdomain.com</p>
                </div>
              </div>
              <div className="flex space-x-4 items-center">
                <button className="bg-secondary p-3  rounded-full text-white text-2xl">
                  <FaLocationDot />
                </button>
                <div className="text-gray-300">
                  <p>No-243, Jaffna Road , Manipay</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-secondary py-5">
        <div className="flex justify-center items-center">
          <a
            href="https://www.matrixmantra.lk/"
            className="text-white hover:text-blue-800"
          >
            Web Design & Development by Matrix Mantra
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
