import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getallPreSclpayment } from "../../slice/PresclpytreportSlice";
import { getPreschollGrade } from "../../slice/preschool_course";
import jsPDF from 'jspdf';
const Presclpaymentreport = () => {
  const { presclReprtData } = useSelector((state) => state.presclpytreport);
  console.log(presclReprtData);
  const { allgrades } = useSelector((state) => state.presclgradeState);
  console.log(allgrades);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getallPreSclpayment());
    dispatch(getPreschollGrade());
  }, [dispatch]);

  const month = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "March" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];

  const [selectMonth, setSelectMonth] = useState();
  const [selectCourse, setSelectCourse] = useState();
  const [selectcourseMonth, setSelectcourseMonth] = useState();
  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    console.log(selectedMonth);
    setSelectMonth(selectedMonth);
  };
  const [dailyIncome, setDailyIncome] = useState([]);
  const [totalDayIncome, setTotalDayIncome] = useState(0);
  const [courseDayIncome,setCourseDayIncome] = useState([])
  const [totalCourseMonthIncome, setTotalCourseMonthIncome] = useState(0);
  // daily income
  useEffect(() => {
    // Filter the report data based on the selected month
    const filtered = presclReprtData.filter((payment) => {
      const paymentDate = new Date(payment.Add_Date);
      return paymentDate.getMonth() + 1 === parseInt(selectMonth);
    });

    // Group data by day and calculate daily income
    const incomeByDay = filtered.reduce((acc, payment) => {
      const paymentDate = new Date(payment.Add_Date).toLocaleDateString();
      if (!acc[paymentDate]) {
        acc[paymentDate] = 0;
      }
      acc[paymentDate] += payment.amount;
      return acc;
    }, {});

    // Convert the grouped data into an array for rendering
    const dailyIncomeArray = Object.keys(incomeByDay).map((date) => ({
      date,
      totalIncome: incomeByDay[date],
    }));

    setDailyIncome(dailyIncomeArray);
  }, [presclReprtData, selectMonth]);

  //monthly income
  useEffect(() => {
    // Filter the report data based on the selected month
    const filtered = presclReprtData.filter((payment) => {
      const paymentDate = new Date(payment.Add_Date);
      return paymentDate.getMonth() + 1 === parseInt(selectMonth);
    });
   console.log(filtered);
   
    // Calculate the total day income
    const totalIncome = filtered.reduce(
      (total, payment) => total + payment.amount,
      0
    );

    setTotalDayIncome(totalIncome);
  }, [presclReprtData, selectMonth]);

  const handleCourseMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectcourseMonth(selectedMonth);
    console.log(selectcourseMonth);
  };

  const handleCourseChange = (e) => {
    const selectedCourse = e.target.value;
    setSelectCourse(selectedCourse);
  };

  
 
useEffect(() => {
    if (!selectcourseMonth || !selectCourse) return;
  
    // Filter payments by selected month
    const filtered = presclReprtData.filter((payment) => {
      const paymentDate = new Date(payment.Add_Date);
      return paymentDate.getMonth() + 1 === parseInt(selectcourseMonth);
    });
  
    // Calculate daily income for the selected course
    const incomeByDay = filtered.reduce((acc, payment) => {
      const paymentDate = new Date(payment.Add_Date).toLocaleDateString();
  
      // Get the course fees for the selected course
      const courseDetails = allgrades.find((c) => c.id == selectCourse);
      const courseFees = courseDetails ? courseDetails.fees : 0;
  
      console.log("Payment Date:", paymentDate);
      console.log("Course Fees:", courseFees);
  
      // Directly compare payment.grade with selectCourse (assuming it's a string/number)
      if (payment.grade == selectCourse) {
        if (!acc[paymentDate]) {
          acc[paymentDate] = 0;
        }
        acc[paymentDate] += Number(courseFees);
      }
  
      return acc;
    }, {});
  
    // Convert the grouped data into an array for rendering
    const dailyIncomeArray = Object.keys(incomeByDay).map((date) => ({
      date,
      totalcourseIncome: incomeByDay[date],
    }));
  
    console.log("Daily Income:", dailyIncomeArray);
    
    setCourseDayIncome(dailyIncomeArray);
  
    const totalCourseIncome = Object.values(incomeByDay).reduce(
      (total, amount) => total + amount,
      0
    );
  
    console.log("Total Course Income for the Month:", totalCourseIncome);
    setTotalCourseMonthIncome(totalCourseIncome);
  }, [presclReprtData, selectcourseMonth, selectCourse, allgrades]);
  
  const generatePdf =() =>{
    const doc = new jsPDF("p", "pt", "a4");
    const content = document.getElementById('content');

    doc.html(content, {
        callback: function (pdf) {
            pdf.save("PrePaymentReport.pdf");
        },
      
    });
  }

return (
    <div className=" h-screen  w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="Pre school Payment Report" />
          </div>

          <div className="  mt-5 h-[85vh]  overflow-auto  bg-white p-3 rounded-xl">
            <div className=" h-full  ">
              {/* day and month */}
              <div className=" ">
                <h1 className="text-bluecolor font-semibold">
                  Day and Month Income For Xpllora
                </h1>
                <table id="content" className="mt-3 w-3/4 text-left   ">
                  <thead className="mt-5  ">
                    <tr className="text-graycolor bg-bluecolor  ">
                      <th className="px-3 py-3 flex">
                        <p>(Date)</p>
                        <select
                          className=" bg-bluecolor"
                          onChange={handleMonthChange}
                        >
                          {month.map((month) => (
                            <option
                              className=""
                              key={month.value}
                              value={month.value}
                            >
                              {month.label}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="px-3 py-3">Day Income</th>
                      <th className="px-3 py-3">Month Income</th>
                    </tr>
                  </thead>
                  <tbody className="  ">
                    {dailyIncome.map(({ date, totalIncome }) => (
                      <tr key={date}>
                        <td className="px-3 py-3">{date}</td>
                        <td className="px-3 py-3">{totalIncome}/=</td>

                        <td></td>
                      </tr>
                    ))}
                    <tr>
                      <th className="px-3 py-3">Month Total Income </th>
                      <td className="px-3 py-3"> </td>
                      <th className="px-3 py-3"> {totalDayIncome}/=</th>
                    </tr>
                  </tbody>
                </table>
                <button className="mt-4 p-2 bg-blue-500 text-white" onClick={() => generatePdf()} >Generate PDF</button>
              </div>

              {/* course and month */}
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presclpaymentreport;
