import React, { useState,useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import Topbar from '../components/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import { addAcademicStaff, clearcreatestaff } from '../slice/academicstaff'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const Addadamecstaff = () => {
    const {isCreate} = useSelector((state) => state.academicstaffState)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(()=>{
        if(isCreate && isCreate.status !== undefined){
          if(isCreate.status){
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Staff Added Successfuly!",
              showConfirmButton: false,
              timer: 1500,
            });
           dispatch(clearcreatestaff())
            setTimeout(() => {
              navigate("/allacademicstaff");
          })
        }
        else{
          alert('error')
        }
        }
       })
   
    const [error,setError] = useState()
    const [staffData,setStaffData] =useState({
        name: '',
        subject:'',
        phone:'',
        email:'',
        nic:''
    })
    const handleChange =(e) =>{
        const {name,value}= e.target
        setStaffData({...staffData, [name] : value})
        setError({...error, [name] :''})
    }
    const handleSubmit =(e) =>{
        e.preventDefault()
       console.log(staffData);
       dispatch(addAcademicStaff(staffData))
    }
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='Add Academic Staff' />
      
      </div>
     
     <div className='  mt-5 bg-white p-3  h-[83vh] '>
      <div className=' h-full  rounded-md'>
        <div className=''> 
        <form>
              <div className='px-5 py-2  '>
              <h1 className='font-bold  px-3 mt-3'>Staff Details:</h1>
              {/* staff name and email */}
              <div className='flex items-center space-x-3 mt-3'>
              <div className='flex flex-col  w-1/2 px-3 py-3  '>
              <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Staff Name
                        </label>
                        <input
                           value={staffData.name}
                           name='name'
                          className={`appearance-none block w-full  bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor`}
                          onChange={handleChange}
                          type="text"
                          placeholder="staff name"
                        />
              </div>
              <div className='flex flex-col w-1/2  p-3 '>
              <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                       Subject
                        </label>
                        <input
                       
                       value={staffData.subject}
                       name='subject'
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
             onChange={handleChange}
                          id="grid-productname"
                          type="text"
                          placeholder="subject"
                        />
              </div>
            
              </div>
              {/* staff phone and password */}
              <div className='flex items-center space-x-3 mt-3'>
              <div className='flex flex-col w-1/2 p-3 '>
              <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                         phone_no
                        </label>
                        <input
                         value={staffData.phone}
                         name='phone'
                         onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                         
                          type="text"
                          placeholder="phone number"
                        />
              </div>
              <div className='flex flex-col w-1/2  px-3 py-3 '>
              <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Email
                        </label>
                        <input
                       value={staffData.email}
                       name='email'
                       onChange={handleChange}
                       
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                       
                          type="text"
                          placeholder="email"
                        />
              </div>
              
              </div>
              <div className='flex flex-col w-1/2  px-3 py-3 '>
              <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          NIC
                        </label>
                        <input
                       value={staffData.nic}
                       name='nic'
                       onChange={handleChange}
                       
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                       
                          type="text"
                          placeholder="NIC"
                        />
              </div>
            
              </div>
              <div className=' w-full flex items-center justify-end mt-5 p-3 px-5'>
                  <button className='py-2 bg-bluecolor w-48  rounded-md text-white ' 
                  onClick={(e) => handleSubmit(e)}
                  >Submit</button>
              </div>
          </form>
        </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Addadamecstaff
