import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import student, { addNewstudent, clearStudentCreated } from "../../slice/student";
import Swal from "sweetalert2";
import Topbar from "../../components/Topbar";
import { getPreschollGrade } from "../../slice/preschool_course";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Addstudent = () => {
  
  // const { user } = useSelector((state) => state.userState);
  const { allgrades} = useSelector((state) => state.presclgradeState)
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  console.log((allgrades));
  //console.log(user.result[0]);
  console.log(users.result[0]);
  //const branchid = user.result[0].branch_id;
  const branchid = users.result[0].branch_id;
  console.log(branchid);
  const { studentData, isCreated } = useSelector((state) => state.studentState);
  console.log(studentData);
  console.log(isCreated);

  const dispatch = useDispatch();
  const navigate = useNavigate();
 useEffect(() =>{
  dispatch(getPreschollGrade())
 },[dispatch])
 
  const [student, setStudent] = useState({
    firstname: "",
    lastname: "",
    dateofbirth: "",
    medium: "",
    religion: "",
    mother_tounge: "",
    sibiling_name: "",
    sib_DOB:"",
    image: "",
    admision_no: "",
    grade: "",
    parent_fname: "",
    parent_DOB: "",
    address: "",
    occupation: "",
    phone_no: "",
    mother_name:'',
    mother_phone:'',
    mother_occupation:'',
    mother_nic:'',
    father_workadd:'',
    mother_workadd:'',
    selectedDate:'',
  
  });

  const [imagesPreview, setImagesPreview] = useState([]);
  const [errors, setErrors] = useState({});

  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const dateofbirthRef = useRef(null);
  const mediumRef = useRef(null);
  const religionRef = useRef(null);
  const mother_toungeRef = useRef(null);
  const sibiling_nameRef = useRef(null);
  const imageRef = useRef(null);
  const admision_noRef = useRef(null);
  const gradeRef = useRef(null);
  const parent_fnameRef = useRef(null);
  const parent_DOBRef = useRef(null);
  const addressRef = useRef(null);
  const occupationRef = useRef(null)
  const phoneRef = useRef(null)



  useEffect(() => {
    if (errors.firstname) {
      firstnameRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.lastname){
      lastnameRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.dateofbirth){
      dateofbirthRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.mother_tounge){
      mother_toungeRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.medium){
      mediumRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.religion){
      religionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.sibiling_name){
      sibiling_nameRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.image){
      imageRef.current.scrollIntoView({ behavior: "smooth" });
    }
      if(errors.admision_no){
        admision_noRef.current.scrollIntoView({ behavior: "smooth" });
      }
    if(errors.grade){
      gradeRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.parent_fname){
      parent_fnameRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.parent_DOB){
      parent_DOBRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.address){
      addressRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.occupation){
      occupationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if(errors.phone_no){
      phoneRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  // setStudent Details
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudent({ ...student, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // form validation
  const validateDate = () => {
    const errors = {};
    let isValid = true;

    if (!student.firstname) {
      errors.firstname = "first name s required";
      isValid = false;
    }

    if(!student.lastname){
      errors.lastname = "last name is required";
      isValid = false;
    }
    const dobRegex = /^\d{4}-\d{2}-\d{2}$/;
    if(!student.dateofbirth){
      errors.dateofbirth = "date of birth is required";
      isValid = false;
    }else if (!dobRegex.test(student.dateofbirth)) {
      errors.dateofbirth = "Date of birth must be in format YYYY-MM-DD";
      isValid = false;
    }

    if(!student.mother_tounge){
      errors.mother_tounge = "Mother Thounge is Required"
      isValid=false
    }
    if(!student.medium){
      errors.medium = "Medium is Required"
      isValid=false

    }

    if(!student.religion){
      errors.religion = "Religion is Required"
      isValid=false
    }

    if(!student.sibiling_name){
      errors.sibiling_name = 'Sibiling Name is requirde'
      isValid = false
    }
    const dobtest = /^\d{4}-\d{2}-\d{2}$/;
    if(!student.sib_DOB){
      errors.sib_DOB = 'Sibiling Date of Birth is required'
    }else if (!dobtest.test(student.sib_DOB)) {
      errors.sib_DOB = "Date of birth must be in format YYYY-MM-DD";
      isValid = false;
    }
    if(!student.image){
      errors.image = 'Image is required'
      isValid = false
    }
    if(!student.admision_no){
      errors.admision_no = "Admision Number is Required"
      isValid = false
    }
    if(!student.grade){
      errors.grade = "Grade is Required"
      isValid = false 
    }
    if(!student.parent_fname){
      errors.parent_fname = "Parent Name is Required"
      isValid = false
    }
    if(!student.parent_DOB){
      errors.parent_DOB = "Parent NIC is Required"
      isValid=false
    }
    if(!student.address){
      errors.address = "Address is Required"
      isValid = false
    }
    if(!student.occupation) {
      errors.occupation = "Occupation is Required"
      isValid = false
    }

  if(!student.phone_no){
    errors.phone_no = "Phone Number is Required"
    isValid = false
  }
  if(!student.mother_name){
    errors.mother_name = "Mother name is required"
    isValid = false
  }
  if(!student.mother_nic){
  errors.mother_nic = " Mother NIC number is required"
  isValid = false
  }else if (student.mother_nic.length !== 10) {
    errors.mother_nic = "Mother NIC number must be exactly 10 characters";
    isValid = false;
  }
   
    if(!student.mother_occupation){
errors.mother_occupation ="Mother Occupation is required"
isValid= false
    }
    if(!student.selectedDate){
      errors.selectedDate = "joined date is required"
      isValid= false
    }
    setErrors(errors);
    return isValid;
  };


const handleImgChange=(e) =>{
  setStudent({
    ...student,
    image: e.target.files[0], // This should be the File object
  });
}

const handleDatehange = (date) =>{
  setStudent({
    ...student,
    selectedDate:date
  })
}

  // success massage for if student create
  useEffect(() => {
    if (isCreated && isCreated.status !== undefined) {
      if (isCreated.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Student Added Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(clearStudentCreated());
        setTimeout(() => {
          navigate("/allstudent");
        }, 1000);
      } else {
        alert("Error");
      }
    }
  }, [isCreated]);


  // const onImageChange = (e) =>{
  //   const files = Array.from(e.target.files);

  //   files.forEach(file=>{
  //     const reader = new FileReader()
  //     reader.onload = () =>{
  //       if(reader.readyState===2){
  // setImagesPreview(oldArray => [...oldArray,file])

  //       }
  //     }
  //   })
  // }
 
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleSubmit = (e) => {
    console.log(student);
    e.preventDefault();
    
    const formData  = new FormData()
    formData.append('firstname', student.firstname)
    formData.append('lastname',student.lastname)
    formData.append('dateofbirth',student.dateofbirth)
    formData.append('medium',student.medium)
    formData.append('religion',student.religion)
    formData.append('mother_tounge',student.mother_tounge)
    formData.append('sibiling_name',student.sibiling_name)
    formData.append('sib_DOB',student.sib_DOB)
    formData.append('image',student.image)
    formData.append('admision_no', student.admision_no)
    formData.append('grade',student.grade)
    formData.append('parent_fname',student.parent_fname)
    formData.append('parent_DOB',student.parent_DOB)
    formData.append('address',student.address)
    formData.append('occupation',student.occupation)
    formData.append('phone_no',student.phone_no)
    formData.append('mother_name',student.mother_name)
    formData.append('mother_phone',student.mother_phone)
    formData.append('mother_occupation',student.mother_occupation)
    formData.append('mother_nic',student.mother_nic)
    formData.append('father_workadd',student.father_workadd)
    formData.append('mother_workadd',student.mother_workadd)
    formData.append('selectedDate',formatDate(student.selectedDate))
    console.log(formData);
    
    const isValid = validateDate();
   
    if (isValid) {
      dispatch(addNewstudent(branchid, formData));
    }
  };

  return (
    <div className=" h-screen  p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-auto bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="Add Pre Student" />
          </div>

          <div className="  mt-5   h-[83vh] overflow-auto  ">
            <div className=" h-auto rounded-md">
              <div className="">
                <form>
                  {/* student basic details */}
                  <div className="bg-white h-auto px-3 py-5 rounded-md">
                    <div className="flex flex-col  w-full px-5">
                      <p className="font-bold mt-3 text-lg">Student Detail :</p>

                      {/* fullname */}
                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            First Name
                          </label>
                          <input
                            ref={firstnameRef}
                            value={student.firstname}
                            name="firstname"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                            ${errors.firstname ? "border-red-500" : ""} `}
                            id="grid-productname"
                            type="text"
                            placeholder="first name"
                          />
                          {errors.firstname && (
                            <p className="error text-sm text-red-500">
                              {errors.firstname}
                            </p>
                          )}
                        </div>
                        <div className="w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-last-name"
                          >
                            Last Name
                          </label>
                          <input
                          ref={lastnameRef}
                            value={student.lastname}
                            name="lastname"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                            ${errors.lastname ? "border-red-500" : ""} `}
                            id="grid-productId"
                            type="text"
                            placeholder="last name"
                          />
                          {errors.lastname && (
                  <p className="error text-sm text-red-500">
                    {errors.lastname}
                  </p>
                )}
                        </div>
                      </div>

                      {/*  date of birth */}
                      <div class="flex flex-wrap  -mx-3 mb-3 ">
                        <div class="w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                            Date of Birth
                          </label>
                          <input
                          ref={dateofbirthRef}
                            value={student.dateofbirth}
                            name="dateofbirth"
                            onChange={handleChange}
                            className={` block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor 
                           ${errors.dateofbirth? "border-red-500":""}`}
                            type="text"
                            placeholder="YY-MM-DD"
                          />
                          {errors.dateofbirth && (
                             <p className="error text-sm text-red-500">
                             {errors.dateofbirth}
                           </p>
                          )}
                        </div>

                        <div className="w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                            mother tounge
                          </label>
                          <div className="flex flex-row w-full space-x-20">
                            <div className="ml-3">
                              {" "}
                              <input
                              ref={mother_toungeRef}
                                type="radio"
                                id="tamil"
                                name="mother_tounge"
                                value="tamil"
                                onChange={handleChange}
                              
                              />
                               {" "}
                              <label for="" className="text-gray-700">
                                Tamil
                              </label>
                            </div>
                            <div className="">
                              <input
                             ref={mother_toungeRef}
                                type="radio"
                                id="english"
                                name="mother_tounge"
                                value="english"
                                onChange={handleChange}
                           


                              />
                               {" "}
                              <label for="" className="text-gray-700">
                                English
                              </label>
                            </div>
                          </div>
                          {errors.mother_tounge && (
                            <p className="error text-sm text-red-500">{errors.mother_tounge}</p>
                          )}
                        </div>
                      </div>
                      {/* medium */}
                      <div class="flex flex-wrap  -mx-3 mb-3 ">
                        <div class="w-1/2 px-3">
                          <label
                            class={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 `}
                            for="grid-orderId"
                          >
                            Medium
                          </label>
                          <select
                          ref={mediumRef}
                            value={student.medium}
                            name="medium"
                            onChange={handleChange}
                            id=""
                            className={`block w-[100%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor `}
                          >
                            <option>select</option>
                            <option value="Tamil">Tamil</option>
                            <option value="english">English</option>
                            <option value="shinhala">Shinhala</option>
                          </select>
                          {errors.medium && (
                            <p className="error text-sm text-red-500">{errors.medium}</p>
                          )}
                        </div>
                        <div class="w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                            Religion
                          </label>
                          <select
                          ref={religionRef}
                            value={student.religion}
                            name="religion"
                            onChange={handleChange}
                            id=""
                            className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor"
                          >
                            <option>select</option>
                            <option value="hindu">Hindu</option>
                            <option value="critian">Cristian</option>
                            <option value="islam">Islam</option>
                          </select>
                          {errors.religion && (
                            <p className="error text-sm text-red-500">{errors.religion}</p>
                          )}
                        </div>
                      </div>

                      {/* mother tounge */}
                      {/* <div className="flex flex-wrap  -mx-3 mb-3 ">
                      
                      </div> */}
                      {/* siter/broter */}
                      {/* <div className="flex flex-wrap  -mx-3 mb-3 ">
                        <div className="w-full px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                            Does the student have any Borther/sister
                          </label>
                          <div className="flex flex-row w-full space-x-20">
                            <div className="">
                              {" "}
                              <input
                                type="radio"
                                id="yes"
                                name="any_sister"
                                value="yes"
                              />
                                <label for="option">Yes</label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id="no"
                                name="any_sister"
                                value="no"
                              />
                                <label for="option">No</label>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* sister/brother details */}
                      <div className="flex flex-wrap -mx-3 mb-3 ">
                        <div className="w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            Sibiling Name
                          </label>
                          <input
                          ref={sibiling_nameRef}
                            value={student.sibiling_name}
                            name="sibiling_name"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
             ${errors.sibiling_name ? "border-red-500" : ""}`}
                            id="grid-productname"
                            type="text"
                            placeholder="siter/brother name"
                          />
                          {errors.sibiling_name && (
                  <p className="error text-sm text-red-500">
                    {errors.sibiling_name}
                  </p>
                )}
                        </div>
                        <div className="w-1/2 px-3 mb-6 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            Date of Birth
                          </label>
                          <input
                            name="sib_DOB"
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
                              ${errors.sib_DOB ? "border-red-500" : ""}`}         
              value={student.sib_DOB}
              onChange={handleChange}
                            id="grid-productname"
                            type="text"
                            placeholder="Grade"
                          />
                          {errors.sib_DOB && (
                  <p className="error text-sm text-red-500">
                    {errors.sib_DOB}
                  </p>
                )}
                        </div>
                      </div>
                      {/* student Address */}
                      <div class="flex flex-wrap  -mx-3 mb-3 ">
                        <div class="w-full px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                            Home Address
                          </label>
                          <input
                          ref={addressRef}
                            value={student.address}
                            name="address"
                            onChange={handleChange}
                            className={` block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
                            ${errors.address ? "border-red-500" : ""}`}
                            type="text"
                            placeholder="address"
                          />
                          {errors.address && (
                            <p className="error text-sm text-red-500 px-3">{errors.address}</p>
                          )}
                        </div>
                      </div>
                      {/* image */}
                      <div className="">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-orderId"
                        >
                          image
                        </label>
                        <div className="mt-1 flex items-center space-x-3">
                          <div className="flex items-center">
                            <label
                              htmlFor="customFile"
                              className="cursor-pointer"
                            >
                              <span className="block py-2 px-4 border border-blue rounded-md text-gray-700 hover:bg-bluecolor hover:text-white cursor-pointer">
                                Choose Images
                              </span>

                              <input
                              ref={imageRef}
                                // value={student.image}
                                name="image"
                                onChange={handleImgChange}
                                // onChange={handleChange}
                                type="file"
                                className="sr-only"
                                id="customFile"
                                multiple
                              />
                            </label>
                            {errors.image && (
                              <p className="error text-sm text-red-500 px-3">{errors.image}</p>
                            )}
                          </div>

                          <div className="block">
                            <div className="flex items-center space-x-2">
                              {/* {imagesPreview.map((image, index) => (
                                          <img
                                              key={index}
                                              src={image}
                                              alt={`Image Preview`}
                                              className="w-14 h-14 object-cover"
                                          />
                                      ))} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* student office details */}
                  <div className="bg-white h-auto px-3 py-5 rounded-md mt-3">
                    {" "}
                    <p className="font-bold mt-3 text-lg">Office Details :</p>
                    <div className="flex flex-wrap -mx-3  mt-3 ">
                      <div className="w-1/2 px-3 mb-3 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Admision NO
                        </label>
                        <input
                        ref={admision_noRef}
                          value={student.admision_no}
                          name="admision_no"
                          onChange={handleChange}
                          className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             ${errors.admision_no ? "border-red-500" : ""}`}
                          
                          type="text"
                          placeholder="Admisin no"
                        />
                        {errors.admision_no && (
                          <p className="error text-sm text-red-500 px-3">{errors.admision_no}</p>
                        )}
                      </div>
                      <div className="w-1/2 px-3 mb-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Grade
                        </label>
                        <select
                        ref={gradeRef}
                          value={student.grade}
                          name="grade"
                          onChange={handleChange}
                          id=""
                          className={`block w-[100%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor 
                          ${errors.grade ? "border-red-500" : ""}`}
                        >
                          <option>select</option>
                          {allgrades.map((g) =>(
                            <option value={g.id}>{g.coursename}</option> 
                          ))}
                          {/* <option value="playGround">Play Ground</option>
                          <option value="LKG">LKG</option>
                          <option value="UKG">UKG</option> */}
                        </select>
                        {errors.grade && (
                          <p className="error text-sm text-red-500 px-3">{errors.grade}</p>
                        )}
                      </div>
                    </div>
                    <div  className="flex flex-wrap -mx-3  mt-3 ">
                    <div className="w-1/2 px-3 mb-3 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                        Joined Date
                        </label>
        <DatePicker
        name="selectedDate"
          selected={student.selectedDate}
           onChange={(date) => handleDatehange(date)}
          dateFormat="yyyy/MM/dd"
          className={`block w-full px-3 py-2 border bg-gray-200 text-gray-700  border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500
             focus:border-indigo-500 sm:text-sm ${errors.selectedDate ? "border-red-500" : ""}`}
          placeholderText="YYYY/MM/DD"
        />
          {errors.selectedDate && (
                          <p className="error text-sm text-red-500 px-3">{errors.selectedDate}</p>
                        )}
        </div>
      
                    </div>
             
                  </div>
                  {/* student father details */}
                  <div className="bg-white h-auto px-3 py-5 mt-3 rounded-md">
                    <div className="flex flex-col  w-full px-5">
                      <p className="font-bold mt-3 text-lg">Father Detail :</p>
                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            Name
                          </label>
                          <input
                          ref={parent_fnameRef}
                            value={student.parent_fname}
                            name="parent_fname"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
             ${errors.parent_fname ? "border-red-500" : ""} `}
                            id="grid-productname"
                            type="text"
                            placeholder="first name"
                          />
                          {errors.parent_fname &&(
                            <p className="error text-sm text-red-500 px-3">{errors.parent_fname}</p>
                          )}
                        </div>
                        <div class="w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                            Nic
                          </label>
                          <input
                          ref={parent_DOBRef}
                            value={student.parent_DOB}
                            name="parent_DOB"
                            onChange={handleChange}
                            className={` block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
                            ${errors.parent_DOB  ? 'border-red-500' : ""}`}
                            type="text"
                            placeholder="nic"
                          />
                          {errors.parent_DOB && (
                            <p className="error text-sm text-red-500 px-3">{errors.parent_DOB}</p>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            occupation
                          </label>
                          <input
                          ref={occupationRef}
                            value={student.occupation}
                            name="occupation"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             ${errors.occupation ? "border-red-500" : ""}`}
                            id="grid-productname"
                            type="text"
                            placeholder="occupation"
                          />
                          {
                            errors.occupation && (
                              <p className="error text-sm text-red-500 px-3">{errors.address}</p>
                            )
                          }
                        </div>
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                            phone Number
                          </label>
                          <input
                          ref={phoneRef}
                            value={student.phone_no}
                            name="phone_no"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             ${errors.phone_no ? "border-red-500" : ""}`}
                            id="grid-productname"
                            type="text"
                            placeholder="phone No"
                          />
                          {errors.phone_no && (
                            <p className="error text-sm text-red-500">{errors.phone_no}</p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                      <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                           Work Address
                          </label>
                          <input
                        
                            value={student.father_workadd}
                            name="father_workadd"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                            id="grid-productname"
                            type="text"
                            placeholder="Father Work Address"
                          />
                         
                        </div>
                        </div>
                    </div>
                  </div>
                  {/* student mother Details */}
                  <div className="bg-white h-auto px-3 py-5 mt-3 rounded-md">
                    <div className="flex flex-col  w-full px-5">
                      <p className="font-bold mt-3 text-lg">Mother Detail :</p>
                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                           Mother Name
                          </label>
                          <input
                         
                            value={student.mother_name}
                            name="mother_name"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
             ${errors.mother_name ? "border-red-500" : ""} `}
                            id="grid-productname"
                            type="text"
                            placeholder="first name"
                          />
                          {errors.mother_name &&(
                            <p className="error text-sm text-red-500 px-3">{errors.mother_name}</p>
                          )}
                        </div>
                        <div class="w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-orderId"
                          >
                           Mother Nic
                          </label>
                          <input
                       
                            value={student.mother_nic}
                            name="mother_nic"
                            onChange={handleChange}
                            className={` block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue
                            ${errors.mother_nic  ? 'border-red-500' : ""}`}
                            type="text"
                            placeholder="nic"
                          />
                          {errors.mother_nic && (
                            <p className="error text-sm text-red-500 px-3">{errors.mother_nic}</p>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                          Mother  occupation
                          </label>
                          <input
                         
                            value={student.mother_occupation}
                            name="mother_occupation"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             ${errors.mother_occupation ? "border-red-500" : ""}`}
                            id="grid-productname"
                            type="text"
                            placeholder="mother_occupation"
                          />
                          {
                            errors.mother_occupation && (
                              <p className="error text-sm text-red-500 px-3">{errors.mother_occupation}</p>
                            )
                          }
                        </div>
                        <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                           Mother phone Number
                          </label>
                          <input
                       
                            value={student.mother_phone}
                            name="mother_phone"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             ${errors.mother_phone ? "border-red-500" : ""}`}
                            id="grid-productname"
                            type="text"
                            placeholder="phone No"
                          />
                          {errors.mother_phone && (
                            <p className="error text-sm text-red-500">{errors.mother_phone}</p>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mb-3 mt-3 ">
                      <div className="w-1/2 px-3 mb-3 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-first-name"
                          >
                           Work Address
                          </label>
                          <input
                        
                            value={student.mother_workadd}
                            name="mother_workadd"
                            onChange={handleChange}
                            className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                            id="grid-productname"
                            type="text"
                            placeholder="Mother Work Address"
                          />
                         
                        </div>
                        </div>
                    </div>
                  </div>


                  <div className="flex mt-5 mr-10 md:justify-end justify-center">
                    <button
                      className="px-5 py-2 text-white bg-bluecolor rounded-md w-56 hover:bg-graycolor text-xl"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// const mapstatetoprops = ({ prestudentstate }) =>({
//   prestudent : prestudentstate.prestudent
// })
// const mapdispatchtoprops = {addPreStudent}
export default Addstudent;
