import React, { useState } from 'react'
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineMarkEmailUnread } from "react-icons/md"
import { HashLink as Link } from 'react-router-hash-link';
import { IoReorderThreeSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import logo from '../../image/logo.png'
// import { Link } from 'react-router-dom';
const Header = () => {
  const [menuopen, setMenuopen] = useState(false)
  return (
    <div className='top-0 md:w-full w-screen  z-50 fixed '>
        {/* top navbar */}
    <div className='md:flex h-20 hidden'>
      <div className='bg-secondary w-1/4 flex items-center px-5 '>
      
      <div className=' flex space-x-28 items-center '>
        <p className='text-white font-semibold text-lg'>XPLLORA</p>
        <div className='flex  space-x-4'>
            <FaFacebookF className='text-3xl text-white bg-primary rounded-full p-1' />
            <FaInstagram className='text-3xl text-white bg-primary rounded-full p-1' />
            <FaYoutube className='text-3xl text-white bg-primary rounded-full p-1' />
        </div>

      </div>
      </div>
     
      <div className='bg-primary '>
      <div style={{
        width: '0',
        height: '0',
        borderTop: '40px solid transparent',
        borderLeft: '60px solid #e01e1e',
        borderBottom: '40px solid transparent'
      }} >
      
      </div>
     
       
      <div>
       
      </div>
      </div>
      <div className='bg-primary px-20 w-full flex justify-end items-center'> 
      <div className='flex space-x-4'>
         <div className='border-r-[1px] border-r-red-400 flex space-x-2 px-2 items-start'>
            <LuPhoneCall className='text-5xl text-secondary  p-1' />
            <div className='text-white'>
                <p className='text-lg '>Call Now!</p>
                <p className='font-semibold'>021-2256200</p>
            </div>
         </div>
         <div className='flex space-x-2'>
          <MdOutlineMarkEmailUnread className='text-5xl text-secondary  p-1 ' />
          <div className='text-white'>
            <p className='text-lg '>Email Us!</p>
            <p className='font-semibold'>info@xplorahome.com</p>
          </div>
         </div>
        </div>
      </div>
    </div>

{/* menu bar for mobile */}
<div className='bg-white shadow-xl md:hidden px-3 py-4 text-2xl flex  items-center  justify-between'>
<div className='h-10 w-32'>
        <img src={logo} alt='' className='h-full w-full object-cover' />  
        </div>
       
  <div className='text-2xl'> {menuopen ? <button onClick={() => setMenuopen(false)}> <RxCross2 size={34} /></button> : <button onClick={() => setMenuopen(true)}><IoReorderThreeSharp size={34} /></button>}</div>
</div>
    {/* menu bar */}
    
    <div className='md:block hidden '>
    <div className='bg-white shadow-xl '>
        
    <div className='container mx-auto flex justify-between items-center font-nav py-3 px-10  '>
        <div className='h-14 w-40'>
        <img src={logo} alt='' className='h-full w-full object-cover' />  
        </div>
        <div>
    <ul className="flex space-x-10 font-semibold text-lg ">
            <li>
              <Link
                to="/"
              
                className="cursor-pointer hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/aboutus"
            
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/coursepage"
             
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                Courses
              </Link>
            </li>
            <li>
              <Link
                to="/gallery"
             
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
             Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/cotctus"
                
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                Contact
              </Link>
            </li>
          </ul></div>
          <div>
          <Link to='/login'>
            <button className='bg-secondary py-3 px-14 text-white font-bold text-xl'>
                
                Login
                
            </button>
            </Link>
          </div>
    </div>
    </div>
    </div>

   {/* mobile menu */}
   {menuopen && (
  <div className='w-full h-auto py-2 bg-white md:hidden '>
   <ul className="flex space-y-10 flex-col font-semibold text-lg py-5 px-5 ">
            <li className=''>
              <Link
                to="/"
              
                className="cursor-pointer hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/aboutus"
            
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/coursepage"
             
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                Courses
              </Link>
            </li>
            <li>
              <Link
                to="/gallery"
             
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
             Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/cotctus"
                
                className="cursor-pointer  hover:text-secondary hover:bg-primary rounded-md py-2 px-5"
              >
                Contact
              </Link>
            </li>
            <li>
            <Link to='/login'>
            <button className='bg-secondary py-3 px-14 text-white font-bold text-xl'>
                
                Login
                
            </button>
            </Link>
            </li>
          </ul>
     </div>
   )}
 
    </div>

  )
}

export default Header
