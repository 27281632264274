import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  addPreSclPymentStd,
  clearPrePayment,
  getPreSclPymenSingletStd,
  getPreSclPymentStd,
  getPreSclSingleStdPaymentData,
} from "../slice/prescl_payment";
import Select from "react-select";
import {
  AllActivities,
  getSingleExtraActivity,
 
} from "../slice/extraActivitySlice";
import Swal from "sweetalert2";
import {
  addNewActivityPayment,
  clearaddExtraActivityPayment,
  getStudentActiviy,
  getSingleStdActivityPymentData,
} from "../slice/extraactivityPayment";
import Paymentreceipt from "./Paymentreceipt";
import { useNavigate } from "react-router-dom";

const Preschoolpayment = () => {


  const { paymentStudent, isPayed , singlePrestd, SingleStpayment_Data} = useSelector(
    (state) => state.presclPaymentState
  );
  console.log( SingleStpayment_Data);
  
  console.log(singlePrestd);
  
  console.log(isPayed);
  const { activity } = useSelector((state) => state.extraActivityState);
  const { isPay, studentActivity,singlePreActSd_Data } = useSelector(
    (state) => state.extraActivityPayment
  );
  console.log(singlePreActSd_Data);
  
  console.log(isPay);
  console.log(activity);
  console.log(paymentStudent);
  console.log(studentActivity);
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  const staffId = users.result[0].staff_id;
  // const { user } = useSelector((state) => state.userState);
  // const staffId = user.result[0].staff_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const studentName = paymentStudent?.lastname;


  useEffect(() => {
    dispatch(AllActivities());
  }, [dispatch]);

  const [studentId, setStudentId] = useState("");
  const [error, setError] = useState({});
  const validate = () => {
    let error = {};
    let isValid = true;

    if (!studentId) {
      error.student_id = "Please enter student Id";
      isValid = false;
    }

    setError(error);
    return isValid;
  };
  const studentidRef = useRef(null);
  useEffect(() => {
    if (error.student_id) {
      studentidRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);
  const [notstudent, setNotstudent] = useState();

  // fetch the stuent and payment details
  const handleClick = () => {
    const isValid = validate();
    if (isValid) {
      console.log(studentId);
      dispatch(getPreSclPymentStd(studentId));
      dispatch(getPreSclSingleStdPaymentData(studentId))
      dispatch(getSingleStdActivityPymentData(studentId))
      setError({ ...error, student_id: "" });
      if(!paymentStudent){
        setNotstudent('Student not found')
      }
      if(paymentStudent){
        setNotstudent('')
      }
      else{
        setNotstudent('')
      }
    }
  };

  // check if previous payment
  const getCorseMonthStatus = (month) => {
    // Filter payments for the given month
    const paymentsForMonth = SingleStpayment_Data.filter(
      (payment) => {
        
        const monthsPaidFor = JSON.parse(payment.pay_month);
    // Check if the current month is included in the list of months paid for
    return monthsPaidFor.includes(month);
      }
    );
  // Check the payment status for the month
  if (paymentsForMonth.length === 0) {
    return 'red'; // No payment
  } else {
    return 'green'; // Full payment
  }
};

//check previous Extra Activity payment
const getActivityMonthStatus = (month) => {
  // Filter payments for the given month
  const paymentsForMonth = singlePreActSd_Data.filter(
    (payment) => payment.month.includes(`[${month}]`)
  );

  // Check the payment status for the month
  if (paymentsForMonth.length === 0) {
    return 'red'; // No payment
  } else if (
    paymentsForMonth.some(
      (payment) =>
        JSON.parse(payment.activityInfo ).length === 1 &&
        paymentsForMonth.length === 1
    )
  ) {
    return 'orange'; // Partial payment
  } else if (paymentsForMonth.length > 1 || paymentsForMonth[0].activityInfo .length > 1) {
    return 'green'; // Full payment
  }
};
  const month = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "March" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 5; i++) {
      years.push({
        value: currentYear - i,
        label: (currentYear - i).toString(),
      });
    }
    return years;
  };
  //  course payment
  const yearOptions = generateYearOptions();
  const [totalamount, setTotalamount] = useState(0);
  const [pay_month, setPay_month] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [extrafees, setExtrafees] = useState(0);
  const [nettotal, setNettotal] = useState(0);

  // extra Activity
  const [selectedActivities, setSelectedActivities] = useState([]);

  const [acivitypay_month, setAcivitypay_month] = useState([]);
  const [activityselectYear, setActivityselectYear] = useState(
    new Date().getFullYear()
  );
  const [activityInfo, setActivityInfo] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [billinfo, setBillinfo] = useState([]);
  const [updatebill, setUpdatebill] = useState([]);
  // Handle checkbox change
  const handleActivityCheckboxChange = (selectedActivity, isChecked) => {
    let updatedActivity;
    let updatedActivityInfo;
    let billactivity;
    let updatebillinfo;
    if (isChecked) {
      updatedActivityInfo = [...activityInfo, selectedActivity.activity_id];
      updatedActivity = [...selectedActivities, selectedActivity];
      billactivity = [...billinfo, selectedActivity.activity_name];
      updatebillinfo = [...updatebill, selectedActivity];
    } else {
      updatedActivityInfo = activityInfo.filter(
        (id) => id !== selectedActivity.activity_id
      );
      updatedActivity = selectedActivities.filter(
        (activity) => activity.activity_id !== selectedActivity.activity_id
      );
      billactivity = billinfo.filter(
        (name) => name !== selectedActivity.activity_name
      );
      updatebillinfo = updatebill.filter(
        (activity) => activity.activity_id !== selectedActivity.activity_id
      );
    }

    setActivityInfo(updatedActivityInfo);
    setSelectedActivities(updatedActivity);
    setBillinfo(billactivity);
    setUpdatebill(updatebillinfo);
  };
  // calculate bill amount
  const billAmount = () => {
    return updatebill.reduce(
      (total, activity) => total + (activity.fees || 0),
      0
    );
  };
  // Calculate total amount based on selected activities
  const calculateTotalAmount = () => {
    return selectedActivities.reduce(
      (total, activity) => (total += activity.fees),
      0
    );
  };

  //select year for extra activity
  const handleActivitySelectYear = (selectedOption) => {
    setActivityselectYear(selectedOption.value);
  };
  //select months
  const [activityMonthError, setActivityMonthError] = useState()
  const handleActivitySelectMonth = (selectedoptn) => {
    setAcivitypay_month(selectedoptn);
    if(selectedoptn>0){
      setActivityMonthError('')
    }

  };
  // const handleCheckboxChange = (fees, isChecked) => {
  //   if (isChecked) {
  //     setTotalamount(fees);
  //     const total = Number(fees) + Number(extrafees);
  //     setNettotal(total);
  //   }
  // };
  const [pdfmonth, setPdfmonth] = useState([]);
  const [courseMonthError,setCourseMonthError] = useState()
  const handleSelectMonth = (selectedoptn) => {
    setPay_month(selectedoptn);
    setPdfmonth(selectedoptn);
    const total = totalamount * selectedoptn.length;
    const netTotal = nettotal * selectedoptn.length;
    setTotalamount(total);
    setNettotal(netTotal);
    if(selectedoptn>0){
      setCourseMonthError('')
    }

  };
  const handleSelectYear = (selectedOption) => {
    setSelectedYear(selectedOption.value);
  };
  const handleAnnualClick = () => {
    setPay_month(month);
    const total = totalamount * 12;
    const netTotal = nettotal *12;
    setTotalamount(total);
    setNettotal(netTotal);
  };
  const handleTermClick = (term) => {
    let termMonths = [];
    if (term === "Term-I") {
      termMonths = month.slice(0, 4);
    } else if (term === "Term-II") {
      termMonths = month.slice(4, 8);
    } else if (term === "Term-III") {
      termMonths = month.slice(8, 12);
    }
    setPay_month(termMonths);
    const total = totalamount * 4;
    const netTotal = nettotal * 4;
    setTotalamount(total);
    setNettotal(netTotal);
  };
  const[courseAmountError, setCourseAmountError] = useState()
const courseAmountValidation = () => {
  let valid = true
  if(!totalamount){
   setCourseAmountError('select the course')
   valid= false
  }
  else{
    setCourseAmountError('')
    valid = true
  }
  return valid
}
  // pre scl payment submit function
  const handleSubmit = () => {
    const isValid = validate();
    const  amountisValid = courseAmountValidation()

    if (isValid && amountisValid) {
      if(pay_month.length === 0){
        setCourseMonthError('Select atleast one month')
      }
      else{
        const paymentDate = {
          studentId,
          staffId,
          totalamount,
          pay_month: pay_month.map((option) => option.value),
          selectedYear,
          grade: paymentStudent?.id,
          pdfmonth: pay_month.map((option) => option.label),
        };
  
        console.log(paymentDate);
        dispatch(addPreSclPymentStd(paymentDate));
      }
     
    }
  };
  const [activityAmountError,setActivityAmountError] = useState()
  const activityAmountValidation =() =>{
    let valid = true
    let total = calculateTotalAmount()
    if(!total){
      setActivityAmountError('select the activity')
      valid = false
    }else{
      setActivityAmountError('')
      valid = true
    }
    return valid

  } 
   //  activity submit function
  const handleActivitySubmit = () => {
    const isValid = validate();
    const amountisValid = activityAmountValidation()
    if (isValid && amountisValid) {
      if(acivitypay_month.length === 0){
        setActivityMonthError('Select atleast one month')
      }else{
        const activityData = {
          studentId,
          staffId,
          activityInfo,
          totalAmount: calculateTotalAmount(),
          acivitypay_month: acivitypay_month.map((option) => option.value),
          activityselectYear,
        };
        console.log(activityData);
        dispatch(addNewActivityPayment(activityData));
      }
    
    }
  };
  const courseName = paymentStudent?.coursename;
  const paymentData = {
    studentId,
    studentName,
    staffId,
    totalamount,
    selectedYear,
    courseName,
    pdfmonth: pay_month.map((option) => option.label),
  };
  // success msg for pre scl payment
  useEffect(() => {
    if (isPayed && isPayed.status !== undefined) {
      if (isPayed.status === "paidAlready") {
        Swal.fire({
          title: "Already Paid For This Month",
          showClass: {
            popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
          },
          hideClass: {
            popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
          },
        });
        dispatch(clearPrePayment());
      } else if (isPayed.status === "paid") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Course Fees Paid Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(clearPrePayment());
        navigate("/paymentreceipt", { state: { paymentData } });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    }
  }, [isPayed, dispatch]);

  const activityData = {
    studentName,
    billinfo,
    bill: billAmount(),
    acivitypay_month: acivitypay_month.map((option) => option.label),
    activityselectYear,
  };

  // success msg for extra activity payment
  useEffect(() => {
    if (isPay && isPay.status !== undefined) {
      if (isPay.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Extra Activity Fees Paid Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(clearaddExtraActivityPayment());
        navigate("/extraactivityreceipt", { state: { activityData } });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    }
  }, [isPay]);

 
  const [updatecourseFlag,setUpdatecourseFlag] = useState(false)
  const handleMonth = (month) => {
    const isValid = validate();
    if (isValid) {
      console.log(studentId);
      console.log(month);

      dispatch(getStudentActiviy(studentId, month, selectedYear));
      setUpdateFlag(true); // Set flag to true to indicate an update is pending
      dispatch(getPreSclPymenSingletStd(studentId,month))
      setUpdatecourseFlag(true)
    }
  };
 
  useEffect(() => {
    if (
      updateFlag &&
      Array.isArray(studentActivity?.Result) &&
      studentActivity?.Result?.length > 0
    ) {
      const paidActivity = studentActivity.Result[0]?.activityInfo;
      const updatedActivity = activity.filter((activit) =>
        paidActivity.includes(activit.activity_id)
      );
      const activityInfo = updatedActivity.map((a) => a.activity_id);

      setSelectedActivities(updatedActivity);
      setActivityInfo(activityInfo);
      setUpdateFlag(false); // Reset the flag
      console.log(updatedActivity);
    }  if (
      updateFlag &&
      studentActivity?.Result?.length === 0
    ){
      setSelectedActivities([]);
      setActivityInfo([]);
   
    }
  }, [studentActivity, activity, updateFlag]);


const [courseInfo,setCourseInfo] = useState([])
const handleCheckboxChange = (courseId,fees, isChecked) => {
  if (isChecked) {
    // Add the courseId to the courseInfo array if it's checked
    setCourseInfo((prevCourseInfo) => [...prevCourseInfo, courseId]);
    setTotalamount(fees);
  //     const total = Number(fees) + Number(extrafees);
  //     setNettotal(total);
  } else {
    // Remove the courseId from the courseInfo array if it's unchecked
    setCourseInfo([]);
  }
};
useEffect(() => {
  if (
    updatecourseFlag &&
    Array.isArray(singlePrestd?.Result) &&
    singlePrestd?.Result?.length > 0
  ) {
    console.log("pre Info", singlePrestd);

    const paidCourse = singlePrestd.Result[0].grade ;
    console.log("paid course id", paidCourse);

    setCourseInfo(Array.isArray(paidCourse) ? paidCourse : [paidCourse]);
    setUpdatecourseFlag(false);
  }  if (
    updatecourseFlag &&
    singlePrestd?.Result?.length === 0
  ) {
    setCourseInfo([]);
    setUpdatecourseFlag(false);
  }
}, [singlePrestd, updatecourseFlag]);
  
  const getCheckedState = (activityId) => {
    return activityInfo.includes(activityId);
  };

  const checkCourseState = (courseId) =>{
    return courseInfo.includes(courseId)
  }
  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="PRE SCHOOL PAYMENT" />
          </div>

          <div className="  h-[83vh] overflow-auto ">
            <div className=" h-full  ">
              <div className="mt-5 ">
                {/* student id Search */}
                <div className="  mt-5 bg-white py-5 rounded-md mr-3">
                  <div className="flex justify-center items-center space-x-3">
                    <p>Sudent ID:</p>
                    <input
                      name="student_id"
                      ref={studentidRef}
                      value={studentId}
                      onChange={(e) => setStudentId(e.target.value)}
                      className={`appearance-none block w-1/2 bg-gray-200  border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                      type="text"
                      placeholder="student Id"
                    />
                    <button
                      className="bg-bluecolor px-10 py-3 rounded-md text-white "
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the page refresh
                        handleClick(); // Call your search handler
                      }}
                      disabled={!studentId?.trim()}
                    >
                      Search
                    </button>
                  </div>
                  <p className="text-red-500 text-xs text-center">
                    {error.student_id}
                  </p>
                  <p className="text-red-500 text-xs text-center">
                    {notstudent}
                  </p>
                </div>
                {/* student Details */}
                <div className=" mt-10 bg-white rounded-md mr-3">
                  <div className="w-full bg-bluecolor text-white text-center font-semibold py-3 rounded-t-md ">
                    <p>Student Details</p>
                  </div>
                  <div className="p-3 flex items-center   flex-row w-full ">
                    <div className="w-1/2 flex flex-col space-y-2">
                      <p>
                        Student ID:
                        <span className="font-semibold px-2 mb-2">
                          {studentId}
                        </span>{" "}
                      </p>
                      <p>
                        Student Name:
                        <span className="font-semibold px-2">
                          {paymentStudent?.firstname} {paymentStudent?.lastname}
                        </span>{" "}
                      </p>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <p>
                        Mobile Number:
                        <span className="font-semibold px-2">
                          {paymentStudent?.phone}
                        </span>{" "}
                      </p>
                      <p>
                        Address:
                        <span className="font-semibold px-2">
                          {paymentStudent?.address}
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
                {/* student Details end */}
                <div className="flex mt-10 space-x-5 mr-3 h-auto">
                  {/* extra activity */}
                  <div className="w-1/2 bg-white rounded-md ">
                    <div className="bg-bluecolor py-3  text-white font-medium rounded-t-md text-center">
                      <p>Extra Activity Details </p>
                    </div>
                    <div className="px-8 mt-5 py-5">
                      {activity.map((a) => (
                        <div
                          key={a.activity_name}
                          className="flex justify-between "
                        >
                          <input
                            className="text-2xl"
                            type="checkbox"
                            value={a.activity_id}
                            onChange={(e) =>
                              handleActivityCheckboxChange(a, e.target.checked)
                            }
                            checked={getCheckedState(a.activity_id)}
                          />
                          <span className="capitalize tracking-wide text-md font-semibold mb-2">
                            {a.activity_name}
                          </span>
                          <span className="font-semibold mb-2">{a.fees}</span>
                        </div>
                      ))}
                      <div className="mt-10 flex space-x-4">
                        <div className="w-1/2">
                          <Select
                            className="bg-gray-200  p-2 rounded-md"
                            options={yearOptions}
                            value={yearOptions.find(
                              (year) => year.value === selectedYear
                            )}
                            onChange={handleActivitySelectYear}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={true}
                          />
                        </div>
                        <div className="w-1/2">
                          <Select
                            className="bg-gray-200 w-full p-2 rounded-md"
                            options={month}
                            value={acivitypay_month}
                            onChange={handleActivitySelectMonth}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            isSearchable={true}
                          />
                          {activityMonthError && <p className="text-red-500 text-xs">{activityMonthError}</p>}
                        </div>
                      </div>
                      <div className="flex mt-10">
                        <label className="w-1/2">Extra Activity Total:</label>
                        <input
                          type="text"
                          placeholder="RS."
                          value={`RS. ${calculateTotalAmount()}`}
                          className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                        />
                      </div>
                      {activityAmountError && <p className="text-red-500">{activityAmountError}</p>}

                      <div className="flex h-10 mt-10 justify-end">
                        <button
                          className="bg-bluecolor h-full w-1/2 rounded-md text-white"
                          onClick={() => handleActivitySubmit()}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* payment */}
                  <div className="w-1/2 bg-white rounded-md h-auto ">
                    <div className="bg-bluecolor py-3  text-white font-medium rounded-t-md text-center">
                      <p>Payment Details</p>
                    </div>

                    <div className="px-8 mt-5 py-5">
                      <div
                        key={paymentStudent?.id}
                        className="flex justify-between"
                      >
                        <input
                          className="text-2xl"
                          type="checkbox"
                          value={paymentStudent?.id}
                          onChange={(e) =>
                            handleCheckboxChange(
                              paymentStudent.id,
                              paymentStudent.fees,
                              e.target.checked
                            )
                          }
                          checked={checkCourseState(paymentStudent?.id)}
                        />
                        <span className="capitalize tracking-wide text-md font-semibold mb-2">
                          {paymentStudent?.coursename}
                        </span>
                        <span className="font-semibold mb-2">
                          {paymentStudent?.fees}
                        </span>
                      </div>

                      <div className="mt-8 flex space-x-4">
                        <button
                          className="w-1/2 bg-gray-200 p-2 rounded-md"
                          onClick={() => handleAnnualClick()}
                        >
                          Anual
                        </button>
                        <Select
                          className="bg-gray-200 w-1/2 p-2 rounded-md"
                          options={yearOptions}
                          value={yearOptions.find(
                            (year) => year.value === selectedYear
                          )}
                          onChange={handleSelectYear}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={true}
                        />
                      </div>
                      <div className="mt-8 flex space-x-4">
                        <div className="w-1/3 ">
                          <select
                            className="bg-gray-200 w-full p-4 rounded-md"
                            onChange={(e) => handleTermClick(e.target.value)}
                          >
                            <option value="">Term</option>
                            <option value="Term-I">Term-I</option>
                            <option value="Term-II">Term-II</option>
                            <option value="Term-III">Term-III</option>
                          </select>
                        </div>
                        <div className="w-2/3">
                          <Select
                            className="bg-gray-200 w-full p-2 rounded-md"
                            options={month}
                            value={pay_month}
                            onChange={handleSelectMonth}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            isSearchable={true}
                          />
                          {courseMonthError && <p className="text-red-500 text-xs">{courseMonthError}</p>}
                        </div>
                      </div>

                      {/* <div className="flex mt-10">
                        <label className="w-1/2">Extra Activity:</label>
                        <input
                          type="text"
                          placeholder="RS."
                          value={extrafees}
                          onChange={(e) => {
                            setExtrafees(e.target.value);
                            setNettotal(
                              Number(totalamount) + Number(e.target.value)
                            );
                          }}
                          className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                        />
                      </div> */}
                      <div className="flex mt-10">
                        <label className="w-1/2">Course Total:</label>
                        <input
                          type="text"
                          placeholder="RS."
                          value={`RS. ${totalamount}`}
                          className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                        />
                      </div>

                      <div className="flex mt-10">
                        <label className="w-1/2">Total:</label>
                        <input
                          type="text"
                          placeholder="RS."
                          value={`Rs. ${nettotal}`}
                          className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                        />
                      </div>
                      {courseAmountError && <p className="text-red-500 ">{courseAmountError}</p>}
                      <div className="flex h-10 mt-10 justify-end">
                        <button
                          className="bg-bluecolor h-full w-1/2 rounded-md text-white"
                          onClick={() => handleSubmit()}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-10 px-2">
                <div className="grid grid-cols-12 text-white  gap-2">
                  {month.map((m) => (
                    
                      <button
                      key={m.value}
                         className={`rounded-md  py-2 ${
                          getCorseMonthStatus(m.value) === 'green'
                             ? 'bg-green-500'
                          
                             : 'bg-red-500'
                         }`}
                      onClick={() => handleMonth(m.value)}
                    >
                      {m.label}
                      {/* <div className=" flex "> 
                        <div 
                         key={m.value}
                         className={`rounded-bl-md w-1/2 border-r-black border-r-[1px] ${
                          getCorseMonthStatus(m.value) === 'green'
                             ? 'bg-green-500'
                          
                             : 'bg-red-500'
                         }`}>C</div>
                        <div 
                        key={m.value}
                        className={`rounded-br-md w-1/2  ${
                          getActivityMonthStatus(m.value) === 'green'
                            ? 'bg-green-500'
                            : getActivityMonthStatus(m.value) === 'orange'
                            ? 'bg-orange-500'
                            : 'bg-red-500'
                        }`}
                       
                        >E</div>
                      </div> */}
                    </button>
                    
                  
                  ))}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preschoolpayment;
