import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialstate ={
    isPay :{},
    singleStudent_exam:{},
    studentExampay_data:[]
}

const examPaymentSlice = createSlice({
name:'ExmPay',
initialState:initialstate,
reducers:{
    examPay(state,action){
        state.isPay = action.payload
    },
    clearExamPay(state,action){
        state.isPay = {}
    },
    SingleStudent(state,action){
        state.singleStudent_exam=action.payload
    },
    singleStudentPaymentData(state,action){
        state.studentExampay_data=action.payload
    }
}
})

export const {examPay,SingleStudent,clearExamPay,singleStudentPaymentData} = examPaymentSlice.actions
export default examPaymentSlice.reducer

export function addNewExamPayment (ExamData){
    return async function addNewexamThunk (dispatch,getState) {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/exampayment/addnew`,ExamData)
        const result =data.data
    dispatch(examPay(result))
        console.log(result);
    }
}
export function getExamSingleStudent (id,month){
    return async function singlestudentThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/exampayment/getsinglestudent/${id}/${month}`)
        const result =data.data
    dispatch(SingleStudent(result))
        console.log(result);
    }
}

export function getSingleStudentExamPayment (id,month){
    return async function singlestudentThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/exampayment/getsinglepaymentdata/${id}`)
        const result =data.data
    dispatch(singleStudentPaymentData(result))
        console.log(result);
    }
}