import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../../components/Topbar";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getSingleXstudent, getXstudent, getXstudentAdmin } from "../../slice/xpllorasthudent";
import { MdSearch } from "react-icons/md";
import studentimg from '../../image/icons/student.png'
import student from "../../slice/student";

const AllStudent = () => {
  const navigate = useNavigate();
  const { Xstudent,singleStudent } = useSelector((state) => state.xstudentState);
  console.log(Xstudent);
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  const branchid = users.result[0].branch_id;
  const role = users.result[0].role;
  // const { user } = useSelector((state) => state.userState);
  // const branchid = user.result[0].branch_id;
  // const role = user.result[0].role;
  console.log(role);
  const dispatch = useDispatch();
 
  useEffect(() => {
    if (role == "admin") {
      dispatch(getXstudentAdmin());
    } else {
      dispatch(getXstudent(branchid));
    }
  }, [dispatch]);

  const handleUpdate= (sid) =>{
    navigate(`/updatestudent/${sid}`)
  }
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const handleRowClick = (id) => {
    setSelectedStudentId(id);
  dispatch(getSingleXstudent(id))
  };
  const [searchId,setSearchId] = useState()
  const [filteredStudents,setFilteredStudents] = useState([])
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      console.log(searchId); // Assuming searchId and searchName are your state variables for ID and Name search inputs
      const filtered = Xstudent.filter((student) => {
        // Filter by ID and/or Name (case insensitive)
        return (
          student.id == searchId ||
          student.name.toLowerCase().includes(searchId.toLowerCase())
        );
      });
      setFilteredStudents(filtered);
    }
  };
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='All Xpllora Student' />
      
      </div>
    
     <div className='  mt-5   h-[83vh] w-full flex space-x-5'>
      <div className='bg-white w-2/5    rounded-md p-3'>
      <div className="flex justify-between items-center">
        <p className="text-bluecolor text-xl font-semibold">Students</p>
        <button
  className="bg-bluecolor px-5 py-2 rounded-md text-white text-xs "
  onClick={() => navigate("/addxpllora")}
>
  ADD
  </button>
      </div>
      <div className="mt-5  flex space-x-3  items-center border-2 border-gray-200 w-full rounded-md px-2 py-2 placeholder:text-graycolor ">
        <MdSearch className="text-graycolor text-2xl" />
        <input type="text" value={searchId} onChange={(e) => setSearchId(e.target.value)} onKeyPress={handleKeyPress}  placeholder="search for students or ID" 
        className="hover:border-transparent focus:outline-none w-full" />
      </div>
      <div className="border-b-2 border-b-gray-300 mt-8"></div>
      <div className="overflow-auto h-[63vh]">
      <table className="mt-8 w-full text-left  ">
        <thead>
          <tr className="text-graycolor">
            <th className="px-3 py-3">Photo</th>
            <th className="px-3 py-3">Name</th>
            <th className="px-3 py-3">Student ID</th>
            <th className="px-3 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="  ">
          {(searchId ? filteredStudents : Xstudent).map((student) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor ${selectedStudentId === student.id ? 'bg-bluecolor text-white' : ''}`}
             onClick={() => handleRowClick(student.id)}>
              <td className="px-3 py-4 "></td>
             <td className="px-3 py-4 ">
              <p className="font-medium">{student.name}</p>
            
              </td>
             <td className="px-3 py-4 ">{student.id}</td>
             <td className="px-3 py-4 flex space-x-2 items-center">
             {/* <button className="font-medium text-xl text-green-600 dark:text-green-500 hover:underline"
        onClick={() => handleView(student.id)}>
            <GrView/>
          </button> */}
          <button
            className="font-medium text-lg text-blue-400  hover:text-blue-500"
            onClick={() => handleUpdate(student.id)}
          >
            <FaEdit />
          </button>
          <button className="font-medium text-lg text-red-400 hover:text-red-500">
            <MdDelete />
          </button>
             </td>
          
            </tr>

          ))}
         
        </tbody>
      </table>
      </div>
      </div>
      {singleStudent.map((student) =>(
 <div className="bg-white w-3/5  rounded-md h-[83vh] overflow-auto">
 <div className="bg-bluecolor  rounded-t-md px-8 py-3 flex space-x-10 items-center h-28 ">
  <img src={studentimg} alt="" className='h-20 w-20 rounded-full border-2 border-textcolor p-2' />
  <div className="flex  flex-col items-center text-white">
<p>{student.name}</p>

<p className=""> {JSON.parse(student.course).join(", ")} | student ID: {student.id}</p>

</div>

 </div> 
 <div className="px-5 py-5">
        {/* student information */}
      <div className="border-2 border-gray-200 rounded-md  p-5 ">
        <p className="text-bluecolor text-xl font-medium">Basic Details</p>
        <div className="grid grid-cols-4">
          <div className="mt-3">
            <p className="text-textcolor text-sm">Date of Birth</p>
            <p className="text-bluecolor font-medium">{student.dateofbirth}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">NIC/Postal</p>
            <p className="text-bluecolor font-medium">{student.nic}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">Phone Mobile</p>
            <p className="text-bluecolor font-medium">{student.contact_mobile}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">Phone Lan</p>
            <p className="text-bluecolor font-medium">{student.contact_lan}</p>
          </div>
        </div>
        <div className="grid grid-cols-4 mt-5">
        <div className="mt-3 ">
            <p className="text-textcolor text-sm">Student Address</p>
            <p className="text-bluecolor font-medium">{student.address}</p>
          </div>
          <div className="mt-3 ">
            <p className="text-textcolor text-sm">School Name</p>
            <p className="text-bluecolor font-medium">{student.school_name}</p>
          </div>
          <div className="mt-3">
            <p className="text-textcolor text-sm">Patent</p>
            <p className="text-bluecolor font-medium">{student.name}</p>
            {/* <p className="text-bluecolor font-medium">NIC:{student.nic}</p> */}
            <p className="text-bluecolor font-medium">+94{student.phone}</p>
          </div>
          <div className="mt-3 ">
            <p className="text-textcolor text-sm">Parent Address</p>
            <p className="text-bluecolor font-medium">{student.parent_address}</p>
          </div>
        </div>
      </div>

      {/* student payment information */}
      <div className="border-2 border-gray-200 rounded-md  p-5 h-52 mt-5" >
        <p className="text-bluecolor text-xl font-medium">Paymeny Details</p>
         </div>
     </div>
 </div>
      ))}
     
      </div>
    </div>
    </div>
    </div>
  );
};

export default AllStudent;
