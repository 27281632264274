import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
const initialstate ={
reportData:[]
}

const xplropytreport = createSlice({
    name:"Xpllora payment Slice",
    initialState:initialstate,
    reducers:{
        getAllPayment(state,action){
            state.reportData = action.payload

        }
    }
})

export const {getAllPayment} = xplropytreport.actions
export  default xplropytreport.reducer

export function getallXpllorapayment() {
    return async function getAll(dispatch, getState) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/xstudentreport/getall`);
        const result = response.data;
        console.log(result);
        dispatch(getAllPayment(result));
        return result;
      } catch (error) {
        console.error('Error fetching payment Data:', error);
        throw error;
      }
    };
  }