import React from "react";
import { PiStudentFill } from "react-icons/pi";
import { FaRegUser } from "react-icons/fa";
import { TbMessageChatbot } from "react-icons/tb";
import { IoCalendarOutline } from "react-icons/io5";
const Blog = () => {
  const blog = [
    {
      id: 1,
      title: "Pongal Event",
      img: "https://www.sundaytimes.lk/200126/uploads/DSC_7790.jpg",
      description:
        "Pongal is a vibrant and joyous festival celebrated with great enthusiasm at our preschool. It marks the harvest season and brings with it a sense of gratitude and community.",
      date: "January 14, 2024",
    },
    {
      id: 2,
      title: "Graduation Event",
      img: "https://www.atlantaparent.com/wp-content/uploads/2020/05/iStock-523859380-scaled.jpg",
      description:
        "Our annual cultural event is a highlight of the preschool year, where children showcase their talents and learn about diverse traditions. Through music, dance, drama, and art, our students explore different cultures and express their creativity",
      date: "November 20, 2023",
    },
    {
      id: 3,
      title: "Caltural Event",
      img: "https://www.tamilguardian.com/sites/default/files/Image/pictures/culture/Navarathiri%202011/IMG_5032.jpg",
      description:
        "The preschool graduation event is a significant milestone, celebrating the achievements and growth of our young graduates. It is a proud moment for parents and teachers alike as we honor the hard work and progress of our students.",
      date: "August 10, 2023",
    },
  ];
  return (
    <section className="bg-gray-50 md:px-20 px-3">
      <div className="h-auto container mx-auto md:py-20 py-10">
        <div className="flex space-x-3  justify-center">
          <PiStudentFill className="md:text-3xl text-xl text-secondary" />
          <p className="md:text-xl text-lg font-bold text-secondary">
            Our Blog
          </p>
        </div>
        <p className="md:text-5xl text-2xl font-bold md:mt-5 mt-3 text-center font-heading">
          Latest Blog & News
        </p>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mt-10">
          {blog.map((blog) => (
            <div className="h-[34rem] xs:h-[35rem]  bg-white shadow-xl relative">
              <div className="h-[17rem] w-full ">
                <img
                  src={blog.img}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="p-3">
                <div className="mt-5 flex space-x-3">
                  <div className="flex space-x-2 items-center">
                    <FaRegUser className="text-secondary" />
                    <p>By Admin</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <TbMessageChatbot className="text-secondary" />
                    <p>No Comments</p>
                  </div>
                </div>
                <p className="font-bold md:text-2xl text-xl  mt-3 ">
                  {blog.title}
                </p>
                <p className="mt-3 text-gray-700">{blog.description}</p>
              </div>
              <div className="bg-secondary absolute top-10 left-10 py-2 px-3 flex items-center rounded-md space-x-1">
                <IoCalendarOutline className="text-white md:font-bold text-lg" />
                <p className="text-white md:font-bold">{blog.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
