import React from 'react'
import Header from '../components/Header'
import img1 from '../images/gallery/img1.jpeg'
import img2 from '../images/gallery/img2.jpeg'
import img3 from '../images/gallery/img3.jpeg'
import img4 from '../images/gallery/img4.jpeg'
import img5 from '../images/gallery/img5.jpeg'
import img6 from '../images/gallery/img6.webp'
import sl1 from '../images/slider2.webp';
import sl2 from '../images/slider3.webp';
import sl3 from '../images/slider4.webp';
const Gallery = () => {
const images = [
  { id: 1, src: img1 },
  { id: 2, src: img2 },
  { id: 3, src: img3 },
  { id: 4, src: img4 },
  { id: 5, src: img5 },
  {id:6,src:img6},
  {id:7,src:sl1},
  {id:8,src:sl2},
  {id:9,src:sl3}
]
  return (
    <div>
      <Header />
    <div className='md:mt-48 mt-20 container px-10 md:px-20'> 
        <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
          {images.map((image) => (
            <div className='md:h-96 h-40  md:w-96 w-full bg-slate-200'>
              <img src={image.src} alt='image' className='h-full w-full object-cover' />
            </div>
            ))}
            
        </div>
        <div className='flex items-center justify-center py-10'>
        <div className='flex bg-bluecolor text-white font-bold items-center justify-center  py-4 w-40  '>

         <a href='https://www.facebook.com/share/AypJ69mT5DAx8hgh/?mibextid=qi2Omg'>Show More</a> 
        </div>
        </div>
         </div>
    </div>
  )
}

export default Gallery
