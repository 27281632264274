import React, { useState ,useEffect} from 'react'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { getallXpllorapayment } from '../../slice/xplorapytreportSlice'
import { getAllCourses } from '../../slice/course'
import jsPDF from 'jspdf';

const Xpllorapytrp = () => {
  const {reportData} = useSelector(state => state. xpllorpytmreport)
  const { course } = useSelector((state) => state.courseState);
  console.log(reportData);
  console.log(course);
  
  const dispatch = useDispatch()
    const month = [
        { value: 1, label: "Jan" },
        { value: 2, label: "Feb" },
        { value: 3, label: "March" },
        { value: 4, label: "Apr" },
        { value: 5, label: "May" },
        { value: 6, label: "Jun" },
        { value: 7, label: "Jul" },
        { value: 8, label: "Aug" },
        { value: 9, label: "Sep" },
        { value: 10, label: "Oct" },
        { value: 11, label: "Nov" },
        { value: 12, label: "Dec" },
      ];
      const [filteredData, setFilteredData] = useState([]);
      const [selctMonth,setSelectMonth] = useState()
      const [selectCourse,setSelectCourse] = useState()
      const [totalDayIncome, setTotalDayIncome] = useState(0);
      const [dailyIncome, setDailyIncome] = useState([]);
      const [courseDayIncome,setCourseDayIncome] = useState([])
      const [totalCourseMonthIncome, setTotalCourseMonthIncome] = useState(0);
      // fetch the payment Details
      useEffect(() =>{
        dispatch(getallXpllorapayment()); // Dispatch the selected month value
        dispatch(getAllCourses())
      },[dispatch])

    

      // month select
      const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setSelectMonth(selectedMonth)
    
       }
     const handleCourseChange =(e) =>{
      const selectedcourse = e.target.value;
      setSelectCourse (selectedcourse)
    console.log(selectedcourse);
    
     }
    // daily income
      useEffect(() => {
        // Filter the report data based on the selected month
        const filtered = reportData.filter(payment => {
          const paymentDate = new Date(payment.Add_Date);
          return paymentDate.getMonth() + 1 === parseInt(selctMonth);
        });
    
        // Group data by day and calculate daily income
        const incomeByDay = filtered.reduce((acc, payment) => {
          const paymentDate = new Date(payment.Add_Date).toLocaleDateString();
          if (!acc[paymentDate]) {
            acc[paymentDate] = 0;
          }
          acc[paymentDate] += payment.amount;
          return acc;
        }, {});
    
        // Convert the grouped data into an array for rendering
        const dailyIncomeArray = Object.keys(incomeByDay).map(date => ({
          date,
          totalIncome: incomeByDay[date]
        }));
    
        setDailyIncome(dailyIncomeArray);
      }, [reportData, selctMonth]);
    
      //monthly income
      useEffect(() => {
        // Filter the report data based on the selected month
        const filtered = reportData.filter(payment => {
          const paymentDate = new Date(payment.Add_Date);
          return paymentDate.getMonth() + 1 === parseInt(selctMonth);
        });
    
        // Calculate the total day income
        const totalIncome = filtered.reduce((total, payment) => total + payment.amount, 0);
    
        
        setTotalDayIncome(totalIncome);
      }, [reportData, selctMonth]);
      const [selectcourseMonth,setSelectcourseMonth] = useState()
const handleCourseMonthChange =(event) =>{
  const selectedMonth = event.target.value;
  setSelectcourseMonth(selectedMonth)
  console.log(selectcourseMonth);
  
}
      useEffect(() => {
        if (!selectcourseMonth || !selectCourse) return;
    
        // Filter payments by selected month
        const filtered = reportData.filter((payment) => {
          const paymentDate = new Date(payment.Add_Date);
          return paymentDate.getMonth() + 1 === parseInt(selectcourseMonth);
        });
    
        // Calculate daily income for the selected course
        const incomeByDay = filtered.reduce((acc, payment) => {
          const paymentDate = new Date(payment.Add_Date).toLocaleDateString();
          const courseFees = JSON.parse(course.find(c => c.id === parseInt(selectCourse))?.fees || 0);
    
          // Check if the payment includes the selected course
          if (JSON.parse(payment.course_id).includes(parseInt(selectCourse))) {
            if (!acc[paymentDate]) {
              acc[paymentDate] = 0;
            }
            acc[paymentDate] += courseFees;
          }
          return acc;
        }, {});
    
        // Convert the grouped data into an array for rendering
        const dailyIncomeArray = Object.keys(incomeByDay).map((date) => ({
          date,
          totalcourseIncome: incomeByDay[date],
        }));
    
        setCourseDayIncome(dailyIncomeArray);
        const totalCourseIncome = Object.values(incomeByDay).reduce((total, amount) => total + amount, 0);
    setTotalCourseMonthIncome(totalCourseIncome);

  }, [reportData, selectcourseMonth, selectCourse, course]);
      
  const generatePdf =() =>{
    const doc = new jsPDF("p", "pt", "a4");
    const content = document.getElementById('content');

    doc.html(content, {
        callback: function (pdf) {
            pdf.save("XploPayRp.pdf");
        },
      
    });
  }
     
      


  return (
    <div className=' h-screen  w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='Xpllora Payment Report' />
      
      </div>
     
     <div className='  mt-5 h-[85vh]  overflow-auto  bg-white p-3 rounded-xl '>
      <div className=' h-full  '>
        {/* day and month */}
        <div className=' ' >
          <h1 className='text-bluecolor font-semibold'>Day and Month Income For Xpllora</h1>
        <table id="content" className="mt-3 w-full text-left   ">
        <thead className='mt-5  '>
          <tr className="text-graycolor bg-bluecolor  ">
            <th className="px-3 py-3 flex">
              <p>(Date)</p>
              <select className=' bg-bluecolor' onChange={handleMonthChange}>
                {month.map((month) =>(
                  <option className='' key={month.value} value={month.value}>{month.label}</option>
                ))}
              </select>
            </th>
            <th className="px-3 py-3">Day Income</th>
            <th className="px-3 py-3">Month Income</th>
            
          
        
          </tr>
        </thead>
        <tbody className="  ">
          
        {dailyIncome.map(({ date, totalIncome }) => (
                      <tr key={date}>
                        
                        <td className="px-3 py-3">{date}</td>
                        <td className="px-3 py-3">{totalIncome}/=</td>
                       
                        <td></td>
                       
                     
                      </tr>
                    ))}
                    <tr>
                    <th className="px-3 py-3">Month Total Income </th> 
                    <td className="px-3 py-3"> </td> 
                    <th className="px-3 py-3"> {totalDayIncome}/=</th> 
                    </tr>
                    
                  
                    
        </tbody>
      </table> 
      <button className="mt-4 p-2 bg-blue-500 text-white" onClick={() => generatePdf()} >Generate PDF</button>
      </div>

      {/* course and month */}
      {/* <div className='mt-10 h-[40vh] overflow-auto   bg-white p-3 rounded-xl'>
        
      <h1 className='text-bluecolor font-semibold'>Day courses Income For Xpllora</h1>
      <table className="mt-3 w-full text-left   ">
        <thead className='mt-5  '>
          <tr className="text-graycolor bg-bluecolor  ">
            <th className="px-3 py-3 flex ">
              <p>(Date)</p>
              <select className=' bg-bluecolor' onChange={handleCourseMonthChange}>
                {month.map((month) =>(
                  <option className='' key={month.value} value={month.value}>{month.label}</option>
                ))}
              </select>
            </th>
           
         
            
            <th className="px-3 py-1  "> 
              <p>(course Day Icome)</p>
              <select  className=' bg-bluecolor' onChange={(e)=>handleCourseChange(e)}>
              <option>select</option>
              {course.map((course) =>(
                <option key={course.id} value={course.id}>{course.name}</option>
            ))}
              </select>
           
            </th>
            <th className="px-3 py-1"> Course Month Income</th>
        
          </tr>
        </thead>
        <tbody className="  ">
     
                  
                    
                    {courseDayIncome.map(({date,totalcourseIncome}) =>(
                      <tr key={date}>
                       <td className="px-3 py-3">{date}</td>
                      
   <td className="px-3 py-3">{totalcourseIncome}/=</td>
   </tr>
                        ))}
                    <tr>
                      <th>Total Course Income</th>
                      <th></th>
                      <th>{totalCourseMonthIncome}/=</th>
                    </tr>
        </tbody>
      </table>

             </div> */}
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Xpllorapytrp