import React, { useState,useEffect } from 'react'
import Topbar from '../../components/Topbar'
import Sidebar from '../../components/Sidebar'
import { useDispatch,useSelector } from 'react-redux'
import { getPreschollGrade } from "../../slice/preschool_course";
import { deleteStudent, getStudent, getStudentAdmin } from "../../slice/student";
import { getallPreSclpayment } from "../../slice/PresclpytreportSlice";
import jsPDF from 'jspdf';
const Presclunpaidstd = () => {
    const { allgrades } = useSelector((state) => state.presclgradeState);
    const {users} = useSelector((state) => state.userState2)
    const { studentData } = useSelector((state) => state.studentState);
    const { presclReprtData } = useSelector((state) => state.presclpytreport);
    console.log(allgrades);
    console.log(presclReprtData);
    
    const branchid = users.result[0].branch_id;
  const role = users.result[0].role;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getallPreSclpayment());
      dispatch(getPreschollGrade());
    }, [dispatch]);

    useEffect(() => {
        if (role == "admin") {
          dispatch(getStudentAdmin());
        } else {
          dispatch(getStudent(branchid));
        }
      }, [dispatch]);
      console.log(studentData);
      
    const month = [
        { value: 1, label: "Jan" },
        { value: 2, label: "Feb" },
        { value: 3, label: "March" },
        { value: 4, label: "Apr" },
        { value: 5, label: "May" },
        { value: 6, label: "Jun" },
        { value: 7, label: "Jul" },
        { value: 8, label: "Aug" },
        { value: 9, label: "Sep" },
        { value: 10, label: "Oct" },
        { value: 11, label: "Nov" },
        { value: 12, label: "Dec" },
      ];
      const [selectMonth,setSelectMonth] = useState()
      const [selectCourse,setSelecteCourse] = useState()
      const [student,setStudent] = useState([])
      const [paidstd,setPaidst] = useState([])
      const handleMonthChange =(e) =>{
       const  selectedMonth = e.target.value
       setSelectMonth(selectedMonth)
      }

      const handleCourseChange =(event) =>{
        const selectedCourse = event.target.value
        setSelecteCourse(selectedCourse)
      }

      console.log(selectCourse);

      useEffect(() =>{
        const filtered = studentData.filter((student) =>{
          return student.grade == selectCourse
        })
        console.log(filtered);
        setStudent(filtered)
      },[selectCourse,studentData])

      useEffect(() =>{
        const filtered = presclReprtData.filter((studentData) =>{
           return studentData.pay_month.includes(selectMonth)
        })

        const courseData = filtered.filter((student) =>{
            return student.grade == selectCourse
        })
        console.log(courseData);
        setPaidst(courseData)
      },[selectCourse,selectMonth,presclReprtData])

      const checkPaid = (studentId) =>{
        if(!selectCourse || !selectMonth) {
        return ;
        }
      const paymentstatus =  paidstd.find((pay) => pay.student_id == studentId)
       return  paymentstatus ? "Paid" : "Un paid"
      }

      const generatePdf =() =>{
        const doc = new jsPDF("p", "pt", "a4");
        const content = document.getElementById('content');
    
        doc.html(content, {
            callback: function (pdf) {
                pdf.save("PreUnpaidReport.pdf");
            },
          
        });
      }
  return (
    <div className=" h-screen  w-full bg-slate-100">
    <div className="flex  p-2 w-full h-full space-x-5">
      <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
        <Sidebar />
      </div>
      <div className=" w-4/5   ">
        <div className="px-3 bg-white rounded-md py-2">
          {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
          <Topbar name="Xpllora Unpaid student Report" />
        </div>

        <div className="  mt-10 h-[85vh] bg-white  rounded-md px-5 overflow-auto">
        <div className=" h-full py-3 w-[55%]  ">
              {/* day and month */}
              <div id="content">
               <div className='flex justify-between items-center py-3 px-5'>
                <h1 className="text-bluecolor font-semibold">
                  Unpaid Student
                </h1>
                <div>
                <select className=' bg-bluecolor py-2 px-5 text-white font-semibold text-lg rounded-md' onChange={handleMonthChange}>
                {month.map((month) =>(
                  <option className='' key={month.value} value={month.value}>{month.label}</option>
                ))}
              </select>
              </div>

              <div>
                <select  className=' bg-bluecolor py-2 px-5 text-white font-semibold text-lg rounded-md'
                 onChange={(e) =>handleCourseChange(e)} >
              <option>select</option>
              {allgrades.map((course) =>(
                 <option key={course.id} value={course.id}>
                 {course.coursename}
               </option>
            ))}
              </select>
              </div>
                </div>
                <table className="mt-5 w-full text-left   ">
                  <thead className="mt-5  ">
                    <tr className="text-graycolor bg-bluecolor  ">
                    <th className="px-3 py-3 ">
                       Student ID
                      </th>
                      <th className="px-3 py-3 ">
                       Student Name
                      </th>
                      <th className="px-3 py-3">Phone</th>
                      <th className="px-3 py-3">Status  
                       </th>
                    </tr>
                  </thead>
                  <tbody>
                {
                    student.length>0 ? (
                        
                            student.map((student) =>(
                                <tr key={student.id} className="bg-gray-100">
                                  <td className="px-3 py-4">{student.id}</td>   
                                <td className="px-3 py-4">{student.lastname} {student.firstname}</td>
                                <td className="px-3 py-4">{student.phone}</td>
                             
                                <td className="px-3 py-4">
                                <span className={`${checkPaid(student.id) === 'Paid' ? 'text-green-800 font-semibold px-4 py-2 rounded-lg bg-green-100   text-center' : 'text-red-800 font-semibold px-4 py-2 text-center bg-red-100 rounded-lg'}`}>
                                                                {checkPaid(student.id)}
                                                            </span>
                                </td>
                              </tr>
                            ))
                        
                    ) : (
                        <tr>
                      <td colSpan="3" className="text-center py-5">No students found</td>
                    </tr>
                    )
                }
                   
                 
                </tbody>
                </table>
                </div>
             
                <button className="mt-4 p-2 bg-blue-500 text-white" onClick={() => generatePdf()} >Generate PDF</button>
             
            </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Presclunpaidstd