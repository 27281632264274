import React ,{useEffect}from 'react'
import Sidebar from '../components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import Topbar from '../components/Topbar'
import student from '../image/icons/student.png'
import student1 from '../image/icons/xstudent.png'
import staff1 from '../image/icons/candidate.png'
import course1 from '../image/icons/ebook.png'
import { getStudentAdmin,getStudent } from '../slice/student'
import { getXstudentAdmin,getXstudent } from '../slice/xpllorasthudent'
import { getAcademicStaff } from '../slice/academicstaff'
import { getAllCourses } from '../slice/course'

const Dashboard = () => {
  console.log(process.env.REACT_APP_BASE_URL);
  // const { user } = useSelector((state) => state.userState);
  const { studentData } = useSelector((state) => state.studentState);
  const { Xstudent } = useSelector((state) => state.xstudentState);
  const {staff} = useSelector((state) => state.academicstaffState);
  const { course } = useSelector((state) => state.courseState);
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  
  const dispatch = useDispatch()
   const branchid = users.result[0].branch_id;
  // const role = user.result[0].role;
  const role =users.result[0].role
  console.log(role);
  
  //count of pre school student
  useEffect(() => {
    if (role == "admin") {
      dispatch(getStudentAdmin());
    } else {
      dispatch(getStudent(branchid));
    }
  }, [dispatch]);
//count of xpllora student
useEffect(() => {
  if (role == "admin") {
    dispatch(getXstudentAdmin());
  } else {
    dispatch(getXstudent(branchid));
  }
}, [dispatch]);

// count of Academic staff
useEffect(() =>{
  dispatch(getAcademicStaff())

},[dispatch])
  // fetch the all courses
  useEffect(() => {
    dispatch(getAllCourses());
    console.log(course);
  }, [dispatch]);
 
  return (


    <div className=' h-screen  w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='Dashboard' />
      
      </div>
      <div className=' grid grid-cols-4 mt-5 gap-6'>
        <div className='bg-white rounded-md py-5 px-3 flex space-x-4 items-center'>
          <img src={student} alt='' className='h-14 w-14 bg-slate-100 rounded-full p-3' />
          <div>
            <p className='text-graycolor '>Pre Student</p>
            <p className='text-3xl text-bluecolor font-semibold'>{studentData.length}</p>
          </div>
        </div>
        <div className='bg-white rounded-md py-5 px-3 flex space-x-4 items-center'>
          <img src={student1} alt='' className='h-14 w-14 bg-slate-100 rounded-full p-3' />
          <div>
            <p className='text-graycolor '>Xpllora Student</p>
            <p className='text-3xl text-bluecolor font-semibold'>{Xstudent.length}</p>
          </div>
        </div> 
        <div className='bg-white rounded-md py-5 px-3 flex space-x-4 items-center'>
          <img src={staff1} alt='' className='h-14 w-14 bg-slate-100 rounded-full p-3' />
          <div>
            <p className='text-graycolor '>Staff</p>
            <p className='text-3xl text-bluecolor font-semibold'>{staff.length}</p>
          </div>
        </div> 
        <div className='bg-white rounded-md py-5 px-3 flex space-x-4 items-center'>
          <img src={course1} alt='' className='h-14 w-14 bg-slate-100 rounded-full p-3' />
          <div>
            <p className='text-graycolor '>Xpllora Courses</p>
            <p className='text-3xl text-bluecolor font-semibold'>{course.length}</p>
          </div>
        </div> 
        
      </div>
     <div className='  mt-5 bg-white p-3  h-[60vh] overflow-auto '>
      <div className=' h-full  rounded-md'>
        <div className=''> </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Dashboard
