import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialstate ={
    presclReprtData : []
}
const presclpaymentreport = createSlice({
    name:"preslcreport",
initialState:initialstate,
reducers:{
    allPytPresclReport(state,action){
        state.presclReprtData=action.payload
    }
}
})

export const {allPytPresclReport} = presclpaymentreport.actions
export default presclpaymentreport.reducer

export function getallPreSclpayment() {
    return async function getAll(dispatch, getState) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/presclreport/getalldata`);
        const result = response.data;
        console.log(result);
        dispatch(allPytPresclReport(result));
        return result;
      } catch (error) {
        console.error('Error fetching payment Data:', error);
        throw error;
      }
    };
  }