import React, { useState, useEffect } from "react";
import { PiStudentFill } from "react-icons/pi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
const Courses = () => {
  const xplloraCourses = [
    {
      id: 1,
      name: "Speed Maths",
      img: "https://www.gmaxworld.com/blog/wp-content/uploads/2023/02/speed-maths.png",
    },
    {
      id: 2,
      name: "Karate",
      img: "https://img.freepik.com/free-vector/flat-design-karate-silhouette-set_23-2150720311.jpg?size=338&ext=jpg&ga=GA1.1.1546980028.1719792000&semt=ais_user",
    },
    {
      id: 3,
      name: "keyboard",
      img: "https://m.media-amazon.com/images/S/aplus-media-library-service-media/e284eea9-bb69-4cf5-a722-72f9b7593398.__CR0,0,300,300_PT0_SX300_V1___.jpg",
    },
    {
      id: 4,
      name: "Art",
      img: "https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1904/vadymvdrobot190400430/119992940-close-up-of-a-little-girl-painting-a-picture-over-a-table.jpg?ver=6",
    },
    {
      id: 5,
      name: "Chess",
      img: "https://media.licdn.com/dms/image/D4D12AQG2cZ9LSbCKvA/article-cover_image-shrink_720_1280/0/1679111769798?e=2147483647&v=beta&t=sQT99I0qLKaj9U8WmwDcV-4JBAtnufokOvWyYXvWJ_g",
    },
    {
      id: 6,
      name: "Eloution",
      img: "https://static.panthi.lk/addImages/elocution-classes-for-young-learners-1670773218.jpg",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % xplloraCourses.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? xplloraCourses.length - 1 : prevIndex - 1
    );
  };
  const [visibleCourses, setVisibleCourses] = useState([]);
  useEffect(() => {
    const updateVisibleCourses = () => {
      const isMobile = window.innerWidth < 768; // Adjust the width as needed for mobile view
      setVisibleCourses(
        isMobile
          ? xplloraCourses.slice(currentIndex, currentIndex + 1)
          : xplloraCourses.slice(currentIndex, currentIndex + 3)
      );
    };
    updateVisibleCourses();
  });
  //const visibleCourses = xplloraCourses.slice(currentIndex, currentIndex + 3);
  return (
    <section id="courses" className="h-auto  py-10 md:px-10 px-2">
      <div className="container mx-auto">
        <div className="flex space-x-3 items-center justify-center md:py-2 py-1">
          <PiStudentFill className="text-3xl text-secondary" />
          <p className="md:text-xl font-bold text-secondary ">Our Courses</p>
        </div>
        <p className="md:text-5xl text-xl font-bold md:mt-5 text-center font-heading">
          BEFORT INTERNATIONAL PRE SCHOOL
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:mt-10  mt-3 ">
          <div className="bg-blue-50 hover:bg-white hover:shadow-xl md:h-[30rem] h-auto ">
            <div className="w-full h-[20rem] ">
              <img
                src="https://rnsischool.edu.in/wp-content/uploads/2020/10/Play-group1.jpg"
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
            <div className="px-5 flex flex-col space-y-3 py-3">
              <p className="text-3xl font-bold">Play Group</p>
              <p>
                Seamlessly visualize quality ellectual capital without superior
                collaboration and idea tically
              </p>
              <div className="flex space-x-2 items-center">
                <p className="text-secondary">Read More</p>
                <FaLongArrowAltRight className="text-2xl text-secondary" />
              </div>
            </div>
          </div>

          <div className="bg-blue-50 hover:bg-white hover:shadow-xl md:h-[30rem] h-auto">
            <div className="w-full h-[20rem] ">
              <img
                src="https://www.procaresoftware.com/wp-content/uploads/2021/09/PreschoolReady-1024x683.jpeg"
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
            <div className="py-3 px-5 flex flex-col space-y-3">
              <p className="text-3xl font-bold">LKG</p>
              <p>
                Seamlessly visualize quality ellectual capital without superior
                collaboration and idea tically
              </p>
              <div className="flex space-x-2 items-center">
                <p className="text-secondary">Read More</p>
                <FaLongArrowAltRight className="text-2xl text-secondary" />
              </div>
            </div>
          </div>
          <div className="bg-blue-50 hover:bg-white hover:shadow-xl md:h-[30rem] h-auto">
            <div className="w-full h-[20rem] ">
              <img
                src="https://sunshine-valley.in/wp-content/uploads/2023/04/2-1.jpg"
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
            <div className="py-3 px-5 flex flex-col space-y-3">
              <p className="text-3xl font-bold">UKG</p>
              <p>
                Seamlessly visualize quality ellectual capital without superior
                collaboration and idea tically
              </p>
              <div className="flex space-x-2 items-center">
                <p className="text-secondary">Read More</p>
                <FaLongArrowAltRight className="text-2xl text-secondary" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center  md:flex-row flex-col mt-5">
          <div>
            <p className="md:text-5xl text-xl font-bold md:mt-10 text-center mt-5 ">
              XPLLORA SKILLS DEVELOPMENT COURSED – KIDS
            </p>
          </div>
          <div className="flex  items-center mt-5 ">
            <div
              className="bg-primary p-3 hover:bg-secondary"
              onClick={handlePrev}
            >
              {" "}
              <IoIosArrowBack className="text-white text-2xl" />
            </div>
            <div
              className="bg-primary p-3 hover:bg-secondary"
              onClick={handleNext}
            >
              {" "}
              <IoIosArrowForward className="text-white text-2xl" />
            </div>
          </div>
        </div>
        <div className="grid   grid-cols-1 md:grid-cols-3 gap-10 mt-10">
          {visibleCourses.map((course) => (
            <div className="bg-blue-50 hover:bg-white md:h-[30rem] h-auto  w-full  hover:shadow-xl ">
              <div className="w-full h-[20rem] ">
                <img
                  src={course.img}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="py-3 px-5 flex flex-col space-y-3">
                <p className="text-3xl font-bold">{course.name}</p>
                <p>
                  Seamlessly visualize quality ellectual capital without
                  superior collaboration and idea tically
                </p>
                <div className="flex space-x-2 items-center">
                  <p className="text-secondary">Read More</p>
                  <FaLongArrowAltRight className="text-2xl text-secondary" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;
