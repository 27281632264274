import React from 'react'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { Link } from 'react-router-dom'

const Paymentreportui = () => {
  return (
    <div className=" h-screen  w-full bg-slate-100">
    <div className="flex  p-2 w-full h-full space-x-5">
      <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
        <Sidebar />
      </div>
      <div className=" w-4/5   ">
        <div className="px-3 bg-white rounded-md py-2">
          {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
          <Topbar name="Pre School Payment Report" />
        </div>

        <div className="  mt-10 h-[85vh]  ">
         <div className='flex w-full bg-white rounded-md py-10 px-3 space-x-10'>
          <div className='bg-bluecolor px-3 py-1 rounded-md text-white font-bold text-xl'>
          <Link to={`/presclpayment`}>
          Daily & Month Income 
          </Link>
          </div>
          <div className='bg-bluecolor px-3 py-1 rounded-md text-white font-bold text-xl'>
          <Link to={`/presclcoursepaymentreport`}>
          Daily & Month Course Income
          </Link>
          </div>
          <div className='bg-bluecolor px-3 py-1 rounded-md text-white font-bold text-xl'>
          <Link to={`/presclunpaidstudentreport`}>
          Unpaid Student Details
          </Link>
          </div>
         </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Paymentreportui