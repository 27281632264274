import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/Sidebar";
import { getPreschollGrade } from "../../slice/preschool_course";
import { useDispatch,useSelector } from "react-redux";
import { deleteStudent, getStudent, getStudentAdmin } from "../../slice/student";
const Prestdcount = () => {
    const { allgrades } = useSelector((state) => state.presclgradeState);
    const { studentData } = useSelector((state) => state.studentState);
    const {users} = useSelector((state) => state.userState2)
    const [selectMonth, setSelectMonth] = useState();
    const [selectCourse, setSelectCourse] = useState();
    const [student,setStudent] = useState([])
    const dispatch = useDispatch();
    const branchid = users.result[0].branch_id;
    const role = users.result[0].role;
    useEffect(() => {
        if (role == "admin") {
          dispatch(getStudentAdmin());
        } else {
          dispatch(getStudent(branchid));
        }
      }, [dispatch]);
     
  
    useEffect(() => {
    
      dispatch(getPreschollGrade());
    }, [dispatch]);

   
    const month = [
        { value: 1, label: "Jan" },
        { value: 2, label: "Feb" },
        { value: 3, label: "March" },
        { value: 4, label: "Apr" },
        { value: 5, label: "May" },
        { value: 6, label: "Jun" },
        { value: 7, label: "Jul" },
        { value: 8, label: "Aug" },
        { value: 9, label: "Sep" },
        { value: 10, label: "Oct" },
        { value: 11, label: "Nov" },
        { value: 12, label: "Dec" },
      ];
      const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setSelectMonth(selectedMonth);
      };
  
    
      const handleCourseChange = (event) => {
        const selectedcourse = event.target.value;
        setSelectCourse(selectedcourse);
      };
    
      

      useEffect(() =>{
      const filtered = studentData.filter((std) =>{
        const addDate = new Date(std.Add_Date)
        return addDate.getMonth() +1  === parseInt(selectMonth)
      })

 
      const courseData = filtered.filter((std) =>{
        return std.grade == selectCourse
      })

     
      setStudent(courseData)
      },[student,selectCourse,selectMonth])
  return (
    <div className=" h-screen  w-full bg-slate-100">
    <div className="flex  p-2 w-full h-full space-x-5">
      <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
        <Sidebar />
      </div>
      <div className=" w-4/5   ">
        <div className="px-3 bg-white rounded-md py-2">
          {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
          <Topbar name="Xpllora student Report" />
        </div>

        <div className="  mt-10 h-[85vh] bg-white  rounded-md px-5 overflow-auto">
          <div className=" h-full py-3   ">
            {/* day and month */}
            <div className="flex justify-between items-center py-3">
              <h1 className="text-bluecolor font-semibold">
                Count of New Students
              </h1>
              <div>
                <select
                  className=" bg-bluecolor py-2 px-5 text-white font-semibold text-lg rounded-md"
                  onChange={handleMonthChange}
                >
                  {month.map((month) => (
                    <option
                      className=""
                      key={month.value}
                      value={month.value}
                    >
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <select
                  className=" bg-bluecolor py-2 px-5 text-white font-semibold text-lg rounded-md"
                  onChange={(e) => handleCourseChange(e)}
                >
                  <option>select</option>
                  {allgrades.map((course) => (
                     <option key={course.id} value={course.id}>
                     {course.coursename}
                   </option>
                  ))}
                </select>
              </div>
              <div>
                  <h1 className="text-xl text-bluecolor font-semibold">Count: {student.length}</h1>
              </div>
            </div>
            <table className="mt-5 w-full text-left   ">
              <thead className="mt-5  ">
                <tr className="text-graycolor bg-bluecolor  ">
                <th className="px-3 py-3 ">Student ID</th>
                  <th className="px-3 py-3 ">Student Name</th>
                  <th className="px-3 py-3">Phone</th>
                  <th className="px-3 py-3">Address</th>
                </tr>
              </thead>
              <tbody>
                  {student.map((std) =>(
                      <tr key={std.id} className="bg-gray-100">
                        <td className="px-3 py-4">{std.id}</td>
                        <td className="px-3 py-4">{std.firstname} {std.lastname}</td>
                        <td className="px-3 py-4">{std.phone}</td>
                        <td className="px-3 py-4">{std.address}</td>
                      </tr>
                  ))}
                  
              </tbody>
            </table>

            
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Prestdcount