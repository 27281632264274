import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Topbar from '../../components/Topbar'
import { useDispatch, useSelector } from 'react-redux'
import { getXstudent, getXstudentAdmin } from '../../slice/xpllorasthudent'
import { getAllCourses } from '../../slice/course'
import { getallXpllorapayment } from '../../slice/xplorapytreportSlice'
import jsPDF from 'jspdf';


const Xplloraunpaid = () => {
    const { Xstudent } = useSelector((state) => state.xstudentState);
    const {users} = useSelector((state) => state.userState2)
    const { course } = useSelector((state) => state.courseState);
    const {reportData} = useSelector(state => state. xpllorpytmreport)
 
    const [selectcourse, setSelectcourse] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [selectMonth,setSelectMonth] = useState()
    const branchid = users.result[0].branch_id;
    const role = users.result[0].role;
    //console.log(branchid);
   // console.log(role);
   // console.log(reportData);
    
   // console.log(Xstudent);
    const dispatch = useDispatch()
   

    useEffect(() =>{
        if (role == "admin") {
            dispatch(getXstudentAdmin())
        }else{
            dispatch(getXstudent(branchid))
        }
    },[dispatch])
  useEffect(() =>{
    dispatch(getAllCourses())
    dispatch(getallXpllorapayment())
  },[dispatch])
  const month = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "March" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];
 const handleMonthChange =(event) =>{
    const selectedmonth = event.target.value
    setSelectMonth(selectedmonth)
 }
 console.log(selectMonth);
 
  const handleCourseChange = (e) => {
    const selectedcourse = e.target.value;
    //console.log("Selected course (before state update):", selectedcourse);
    
    setSelectcourse(selectedcourse);
    // Logging here will still show the old value because state update is async
};



  useEffect(() => {
    if (selectcourse) {
        const filtered = Xstudent.filter((student) => {
        
          return student.course.includes(selectcourse);
        });
       // console.log("Filtered students:", filtered);
        setFilteredStudents(filtered);
      } else {
        setFilteredStudents(Xstudent); // Show all students if no course selected
      }
  }, [Xstudent, selectcourse]);
   const [filterData, setFilterData]= useState([])
   useEffect(() => {
    if (selectMonth && reportData.length > 0) {
      const filtered = reportData.filter((payment) => {
        
        return payment.month.includes(selectMonth);
      });
      
      const selectedcourse = course.find(c => c.name == selectcourse)
      console.log("selectes course",selectedcourse);
      
      if(selectedcourse ){
        const courseId = selectedcourse.id;
        console.log(courseId);
   
        const filterCourse = filtered.filter((data) =>{
          return data.course_id.includes(courseId)
      })
        
      setFilterData(filterCourse);
      }
      
      
      
     
    }

 
  }, [selectMonth, reportData, course, selectcourse]);


  const checkPaid = (studentId) =>{
    if(!selectMonth || !selectcourse){
      return ;
    }
    const paymentst = filterData.find((pay) => pay.student_id === studentId)
    return paymentst ? "Paid" : "Un paid"
  }
  
  console.log("filter Data", filterData);
 
  const generatePdf =() =>{
    const doc = new jsPDF("p", "pt", "a4");
    const content = document.getElementById('content');

    doc.html(content, {
        callback: function (pdf) {
            pdf.save("XploUnpaidRp.pdf");
        },
      
    });
  }
  return (
    <div className=" h-screen  w-full bg-slate-100">
    <div className="flex  p-2 w-full h-full space-x-5">
      <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
        <Sidebar />
      </div>
      <div className=" w-4/5   ">
        <div className="px-3 bg-white rounded-md py-2">
          {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
          <Topbar name="Xpllora Unpaid student Report" />
        </div>

        <div className="  mt-10 h-[85vh] bg-white  rounded-md px-5 overflow-auto">
        <div className=" h-full py-3 w-[55%]  ">
             
              <div  id="content">
               <div className='flex justify-between items-center py-3 px-3'>
                <h1 className="text-bluecolor font-semibold">
                  Unpaid Student
                </h1>
                <div>
                <select className=' bg-bluecolor py-2 px-5 text-white font-semibold text-lg rounded-md' onChange={handleMonthChange}>
                {month.map((month) =>(
                  <option className='' key={month.value} value={month.value}>{month.label}</option>
                ))}
              </select>
              </div>

              <div>
                <select  className=' bg-bluecolor py-2 px-5 text-white font-semibold text-lg rounded-md'
                 onChange={(e) =>handleCourseChange(e)} >
              <option>select</option>
              {course.map((course) =>(
                <option key={course.id} value={course.name}>{course.name}</option>
            ))}
              </select>
              </div>
                </div>
                <table className="mt-5 w-full text-left   ">
                  <thead className="mt-5  ">
                    <tr className="text-graycolor bg-bluecolor  ">
                    <th className="px-3 py-3 ">
                       Student ID
                      </th>
                      <th className="px-3 py-3 flex">
                       Student Name
                      </th>
                      <th className="px-3 py-3">Phone</th>
                      <th className="px-3 py-3">Status  
                       </th>
                    </tr>
                  </thead>
                  <tbody>
                  {filteredStudents.length > 0 ? (
                    filteredStudents.map((student) => (
                      <tr key={student.id} className="bg-gray-100">
                         <td className="px-3 py-4">{student.id}</td>
                        <td className="px-3 py-4">{student.name}</td>
                        <td className="px-3 py-4">{student.contact_mobile}</td>
                     
                        <td className="px-3 py-4">
                        <span className={`${checkPaid(student.id) === 'Paid' ? 'text-green-800 font-semibold px-4 py-2 rounded-lg bg-green-100' : 'text-red-800 font-semibold px-4 py-2 bg-red-100 rounded-lg'}`}>
                                                        {checkPaid(student.id)}
                                                    </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-5">No students found</td>
                    </tr>
                  )}
                </tbody>
                </table>
                </div>
                <button className="mt-4 p-2 bg-blue-500 text-white" onClick={() => generatePdf()} >Generate PDF</button>
            
             
            </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Xplloraunpaid