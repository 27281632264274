import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialstate = {
  isCreated: {},
  studentData: [],
  singleStudent: [],
  studentProfile: [],
  prent: [],
  isUpdate: {},
  isDelete: [],
};

const studentSlice = createSlice({
  name: "student",
  initialState: initialstate,
  reducers: {
    addStudent(state, action) {
      state.isCreated = action.payload;
    },
    clearStudentCreated(state, action) {
      state.isCreated = {};
    },
    getAllstudent(state, action) {
      state.studentData = action.payload;
    },
    getAllStudentAdmin(state, action) {
      state.studentData = action.payload;
    },
    SingleStudent(state, action) {
      state.singleStudent = action.payload;
    },
    updateStudent(state, action) {
      state.isUpdate = action.payload;
    },
    clearStudentUpdate(state, action) {
      state.isUpdate = {};
    },
    getstudentProfile(state, action) {
      state.studentProfile = action.payload;
    },
    getparent(state, action) {
      state.prent = action.payload;
    },
    deletePrestudent(state, action) {
      state.isDelete = action.payload;
    },
  },
});

export const {
  addStudent,
  getAllstudent,
  getAllStudentAdmin,
  SingleStudent,
  updateStudent,
  clearStudentCreated,
  getstudentProfile,
  getparent,
  clearStudentUpdate,
  deletePrestudent,
} = studentSlice.actions;
export default studentSlice.reducer;

// add new student
export function addNewstudent(id, studentData) {
  return async function addNewstudentThunk(dispatch, getState) {
    const data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/prestudent/newprestudent/${id}`,
      studentData
    );
    const result = data.data;
    dispatch(addStudent(result));
    console.log(result);
  };
}
// get student by branch id
export function getStudent(id) {
  return async function gettudentThunk(dispatch, getState) {
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/prestudent/getAllStudent/${id}`
    );
    const result = data.data;
    dispatch(getAllstudent(result));
    console.log(result);
  };
}
// get student by admin
export function getStudentAdmin() {
  return async function gettudentadminThunk(dispatch, getState) {
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/prestudent/getAllStudentadmin`
    );
    const result = data.data;
    dispatch(getAllStudentAdmin(result));
    console.log(result);
  };
}

// get single student data
export function getSingleStudent(id) {
  return async function getsinglestudentThunk(dispatch, getState) {
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/prestudent/getsinglestudent/${id}`
    );
    const result = data.data;
    dispatch(SingleStudent(result));
    console.log(result);
  };
}

// student update

export function UpdateStudentDetails(id, student) {
  return async function getsinglestudentThunk(dispatch, getState) {
    const data = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/prestudent/updatestudent/${id}`,
      student
    );
    const result = data.data;
    dispatch(updateStudent(result));
    console.log(result);
  };
}

export function getStudentProfile(id) {
  return async function getStudentProfileThunk(dispatch, getState) {
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/prestudent/studentprofile/${id}`
    );
    const result = data.data;
    dispatch(getstudentProfile(result));
    console.log(result);
  };
}

// get all parents Details
export function getAllParent() {
  return async function getAllParentThunk(dispatch, getState) {
    const data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/prestudent/getallparents`
    );
    const result = data.data.Result;
    dispatch(getAllParent(result));
    console.log(result);
  };
}

//delete pre student
export function deleteStudent(id) {
  return async function deleteStudentThunk(dispatch, getState) {
    const data = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/prestudent/removestudent/${id}`
    );
    const result = data.data.Result;
    dispatch(deleteStudent(result));
  };
}
