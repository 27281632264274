import React, { useEffect } from 'react'
import Sidebar  from '../components/Sidebar'
import Topbar from '../components/Topbar'
import { deleteAcademicstaff, getAcademicStaff } from '../slice/academicstaff'
import { useDispatch, useSelector } from 'react-redux'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'


const Allacademicstaff = () => {
    const {staff} = useSelector((state) => state.academicstaffState)
    console.log(staff);
    const navigate = useNavigate()

    const dispatch = useDispatch()
    useEffect(() =>{
        dispatch(getAcademicStaff())

    },[dispatch])

    const handleUpdate =(id) =>{
        console.log(id);
        navigate(`/editacdemicstaff/${id}`)
    }

    const handleRemove =(id) =>{
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(id);
          dispatch(deleteAcademicstaff(id)).then(()=>{
            dispatch(getAcademicStaff())
          })
        }
      });

    }
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='All Academic Staff' />
      
      </div>
    
     <div className='  mt-5 bg-white p-3  h-[83vh] '>
      <div className=' h-full  rounded-md'>
      <div className="">
                  <table className="  w-full text-left   ">
                    <thead className="bg-bluecolor">
                      <tr className="text-graycolor">
                        <th scope="col" className="px-6 py-3">
                          Staff Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                         Subject
                        </th>
                       
                        <th scope="col" className="px-6 py-3">
                          Email
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Phone
                        </th>
                        <th scope="col" className="px-6 py-3">
                      NIC
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {staff.map((staff) => (
                        <tr className="mt-3 border-b-[1px] border-b-gray-100 ">
                          <td className="px-6 py-4">{staff.staff_name}</td>
                          <td className="px-6 py-4">{staff.subject}</td>
                          
                          <td className="px-6 py-4">{staff.email}</td>
                          <td className="px-6 py-4">{staff.phone}</td>
                          <td className="px-6 py-4">{staff.nic}</td>
                          <td className="px-6 py-4 flex space-x-2 items-center">
                            <button
                              className="font-medium text-xl text-blue-600 dark:text-blue-500 hover:underline"
                              onClick={() => handleUpdate(staff.id)}
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="font-medium text-xl text-red-600 dark:text-red-500 hover:underline"
                              onClick={() => handleRemove(staff.id)}
                            >
                              <MdDelete />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Allacademicstaff
